import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container
} from "reactstrap";

class PayfortContainer extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
   
    return (
      <div>
        <br />
        <h5>Payfort</h5>
        <hr />
        <Container>
          <Row>
            <Col md={{ size: 5 }}>
              configs...
              {/* <br />
              <Button color="primary" onClick={() => this.upload.click()}>
                Upload
              </Button>
              <br />
              <input
                id="myInput"
                type="file"
                ref={ref => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.onChangeFile.bind(this)}
              />
              <br />
              <FormGroup>
                <Label for="exampleSelect">Version</Label>
                <Input
                  type="select"
                  ref="version_select"
                  onChange={this.onChangeVersion.bind(this)}
                >
                  <option value="130">1.30</option>
                  <option value="131">1.31</option>
                  <option value="132">1.32</option>
                  <option value="135">1.35</option>
                </Input>
              </FormGroup>
              {resultHtml}
              {missingkeysHtml}
              {invalidkeysHtml} */}
            </Col>
            <Col md={{ size: 7 }}>
              <br />
              <h3>Create Card</h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PayfortContainer);
