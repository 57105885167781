import mobiagApi from "../api/mobiag-api";

export const getReservations = (dispatch) => {
  dispatch({
    type: "MBG_RESERVATIONS_GET_RESERVATION"
  });
  mobiagApi.reservations.getReservations(
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_RESERVATIONS_GET_RESERVATION_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_RESERVATIONS_GET_RESERVATION_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const createReservation = (body, dispatch) => {
  dispatch({
    type: "MBG_RESERVATIONS_CREATE_RESERVATION"
  });
  console.log('::::::::::::::::::::::::: body: %o', body);
  mobiagApi.reservations.createReservation(
    body,
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_RESERVATIONS_CREATE_RESERVATION_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_RESERVATIONS_CREATE_RESERVATION_FAILURE",
          payload: error
        })
      }
    }
  );
}

