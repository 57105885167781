import mobiagApi from "../api/mobiag-api";

export const getCars = (location, dispatch) => {
  dispatch({
    type: "MBG_CARS_GET_CARS"
  });
  mobiagApi.cars.getCars(
    location, 
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CARS_GET_CARS_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CARS_GET_CARS_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getBrands = (id, dispatch) => {
  dispatch({
    type: "MBG_CARS_GET_BRANDS"
  });
  mobiagApi.cars.getBrands(
    id, 
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CARS_GET_BRANDS_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CARS_GET_BRANDS_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getClasses = (id, dispatch) => {
  dispatch({
    type: "MBG_CARS_GET_CLASSES"
  });
  mobiagApi.cars.getClasses(
    id, 
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CARS_GET_CLASSES_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CARS_GET_CLASSES_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getGroups = (groupCode, dispatch) => {
  dispatch({
    type: "MBG_CARS_GET_GROUPS"
  });
  mobiagApi.cars.getGroups(
    groupCode, 
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CARS_GET_GROUPS_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CARS_GET_GROUPS_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getCarVirtualKey = (carId, phoneUuid, endDate, reservationCode, dispatch) => {
  dispatch({
    type: "MBG_CARS_GET_CAR_VIRTUAL_KEY"
  });
  mobiagApi.cars.getCarVirtualKey(
    carId, phoneUuid, endDate, reservationCode, 
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CARS_GET_CAR_VIRTUAL_KEY_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CARS_GET_CAR_VIRTUAL_KEY_FAILURE",
          payload: error
        })
      }
    }
  );
}



