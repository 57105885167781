import React from "react";

class AppIcon extends React.Component {
  handleOnClick() {
    this.props.onClick(this.props.app);
  }

  render() {
    return (
      <div className="app-icon" onClick={this.handleOnClick.bind(this)}>
        <img src={this.props.app.icon} alt={this.props.app.app_name}></img>
        <span>{this.props.app.app_name}</span>
      </div>
    );
  }
}

export default AppIcon;