import React from "react";
import {
  FormGroup, Label, Input
} from "reactstrap";

class CheckBoxConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checkState
    }
  }

  onChangeChecked() {
    if (!this.props.disabled) {
      let isChecked = !this.state.checked;
      this.setState({
        checked: isChecked
      });
      if (isChecked) {
        if (this.props.onChecked) {
          this.props.onChecked(true);
        }
      }
      else {
        if (this.props.onChecked) {
          this.props.onChecked(false);
        }
      }
    }
  }

  handleOnChange(event) {
    //event.preventDefault();
  }

  render() {
    return (
      <FormGroup className="checkbox-config" onClick={this.onChangeChecked.bind(this)}>
        <Input type="checkbox" checked={this.state.checked} disabled={this.props.disabled} onChange={this.handleOnChange.bind(this)} />
        <Label check>
          {' '}&nbsp;{this.props.label}
        </Label>
        {/* <CustomInput checked={this.state.checked} type="checkbox" id="exampleCustomCheckbox" disabled={this.props.disabled} onChange={this.onChangeChecked.bind(this)} label={this.props.label} /> */}
      </FormGroup>
    );
  }
}

export default CheckBoxConfig;