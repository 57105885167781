import React, { Component } from "react";
import { connect } from "react-redux";
import { getCustomerDetails } from "../../Actions/customerActions";
import {
  Button
} from "reactstrap";
import MethodBox from "../../Components/MethodBox";

class CustomersContainer extends Component {
  render() {
    return <div>
      <br />
      <h5>Customers</h5>
      <hr/>
      <MethodBox title="Get customer Details" result={this.props.getDetailsResult} errorResult={this.props.getDetailsError} error={this.props.getDetailsError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.getCustomerDetails()}>Submit</Button>
      </MethodBox>

    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.customerReducer.loading,
    getDetailsResult: state.customerReducer.getDetailsResult,
    getDetailsError: state.customerReducer.getDetailsError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: () => {
      getCustomerDetails(dispatch);
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersContainer);
