import React from "react";
import { Input, Tooltip } from "reactstrap";

class ConfigInput extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    let defaultValue = this.props.field.type === 'Boolean' ? false : "";
    let value = props.value || defaultValue;
    if (this.props.field.type === 'Array' && value && value.length > 0) {
      value = value.join(',');
    }
    this.state = {
      tooltipOpen: false,
      value: value
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleChange(e) {
    let value = e.target.value;
    console.log('(1) ConfigInput-handleChange: %o', value);
    if (this.props.field.type === 'Boolean') {
      value = !this.state.value;
    }
    if (this.props.field.type === 'Array') {
      value = value.split(',').map((el) => {return el.trim()});
    }
    
    //console.log('(2) ConfigInput-handleChange: %o', value);
    this.setState({
      value: value
    });
    this.props.onChange(this.props.field.key, value);
  }

  renderKeyAndTooltip() {
    return (
      <td>
        <span size="sm">{this.props.field.key}</span>
        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={'tooltip_' + this.props.field.key} toggle={this.toggle}>
          {this.props.field.description}
        </Tooltip>
      </td>
    );
  }

  renderStringInput() {
    return (
      <tr id={'tooltip_' + this.props.field.key} className="config-input config-input-string">
        {this.renderKeyAndTooltip()}
        <td><Input type="text" bsSize="sm" value={this.state.value}
          onChange={this.handleChange.bind(this)} /></td>
      </tr>
    );
  }

  renderBooleanInput() {
    return (
      <tr id={'tooltip_' + this.props.field.key} className="config-input config-input-boolean">
        {this.renderKeyAndTooltip()}
        <td>
          <Input type="checkbox" checked={this.state.value} onChange={this.handleChange.bind(this)} />
          {/* <Input type="text" bsSize="sm" value={this.state.value} onChange={this.handleChange.bind(this)} /> */}
        </td>
      </tr>
    );
  }

  render() {
    if (this.props.field) {
      switch (this.props.field.type) {
        case 'Boolean':
          return this.renderBooleanInput();
        default:
          return this.renderStringInput();
      }
    }
  }
}

export default ConfigInput;