import remoteConfigsApi from "../api/remote-configs";

export const getConfigs = (app_name, app_version, dispatch) => {
  dispatch({
    type: "RC_GETCONFIGS"
  });
  remoteConfigsApi.getRemoteConfigs(
    app_name, app_version,
    (response, error) => {
      if (!error) {
        dispatch({
          type: "RC_GETCONFIGS_SUCCESS",
          payload: response.data
        })
      }
      else {
        //console.log('####################### -------------- response: %o, error: %o', response, error);
        dispatch({
          type: "RC_GETCONFIGS_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const addRemoteConfig = (app_name, app_version, app_config, dispatch) => {
  dispatch({
    type: "RC_CREATECONFIG"
  });
  remoteConfigsApi.addRemoteConfig(
    app_name, app_version, app_config,
    (response, error) => {
      if (!error) {
        dispatch({
          type: "RC_CREATECONFIG_SUCCESS",
          payload: response.data
        });
        getConfigs(app_name, app_version, dispatch);
      }
      else {
        dispatch({
          type: "RC_CREATECONFIG_FAILURE",
          payload: error
        })
      }
    }
  );
}