import axios from "axios";
const _appConfigs = {
  "rc_url": "https://cors-anywhere.herokuapp.com/https://core.mobiag.com/appsconf",
  "rc_url_old": "https://cors-anywhere.herokuapp.com/https://brh8cm1599.execute-api.eu-west-1.amazonaws.com/stage",
  "rc_key": "0OV9TmflIp5LAxxMeIDhP8PUUQJnjRG59D2VNqke"
}

const remoteConfigsApi = {
  post: function (method, params, callback) {
    let url = _appConfigs.rc_url + '/' + method;
    let options = {
      method: 'post',
      url: url,
      crossDomain: true,
      headers: {
        'Content-type': 'application/json',
        'x-api-key': _appConfigs.rc_key,
        'Access-Control-Allow-Origin': '*'
      }
    };
    if (params) {
      options.data = params;
    }
    console.log('[POST] >>> options: %o', options);
    axios(options).then(
      (response) => {
        console.log('[POST] <<< response: %o', response);
        callback(response);
      }
    ).catch(
      (error) => {
        console.log('[POST] <<< error: %o', error);
        callback(null, error);
      }
    );
  },

  post_old: function (method, params, callback) {
    let url = _appConfigs.rc_url_old + '/' + method;
    let options = {
      method: 'post',
      url: url,
      crossDomain: true,
      headers: {
        'Content-type': 'application/json',
        'x-api-key': _appConfigs.rc_key,
        'Access-Control-Allow-Origin': '*'
      }
    };
    if (params) {
      options.data = params;
    }
    console.log('[POST_OLD] >>> options: %o', options);
    axios(options).then(
      (response) => {
        console.log('[POST_OLD] <<< response: %o', response);
        callback(response);
      }
    ).catch(
      (error) => {
        console.log('[POST_OLD] <<< error: %o', error);
        callback(null, error);
      }
    );
  },

  getRemoteConfigsLastVersion: function (app_name, app_version, callback) {
    this.post(
      'getRemoteConfigsLastVersion',
      {
        "app_name": app_name,
        "app_version": app_version
      },
      callback
    );
  },

  getRemoteConfigs: function (app_name, app_version, callback) {
    this.post(
      'getRemoteConfigs',
      {
        "app_name": app_name,
        "app_version": app_version
      },
      callback
    );
  },

  addRemoteConfig: function (app_name, app_version, app_config, callback) {
    this.post(
      'addRemoteConfig',
      {
        "app_name": app_name,
        "app_version": app_version,
        "app_config": app_config
      },
      callback
    );
    this.post_old(
      'addRemoteConfig',
      {
        "app_name": app_name,
        "app_version": app_version,
        "app_config": app_config
      },
      callback
    );
  }
}

export default remoteConfigsApi