import React from "react";
import { Collapse, Table } from "reactstrap";

import I18nElement from './I18nElement';

class I18nBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newData: props.newData || {},
      tableCollapse: true,
      collapseIcon: "+"
    };
  }

  getlanguageValue(lang, key) {
    if (this.state.newData && lang in this.state.newData && key in this.state.newData[lang]) {
      return this.state.newData[lang][key];
    }
    if (this.props.data && this.props.data.i18n && lang in this.props.data.i18n && key in this.props.data.i18n[lang]) {
      return this.props.data.i18n[lang][key];
    }
  }

  getFieldValue(key) {
    //console.log('I18nTab --> getFieldValue(%s) | this.state.newData: %o | this.props.data: %o', key, this.state.newData, this.props.data);
    let value = {};
    if (this.props.data.configs && this.props.data.configs.availableLanguages) {
      this.props.data.configs.availableLanguages.forEach(element => {
        value[element] = this.getlanguageValue(element, key);
      });
    }
    return value;
  }

  handleChange(k, v) {
    let change = this.state.newData || {};
    if (this.props.data.configs && this.props.data.configs.availableLanguages) {
      this.props.data.configs.availableLanguages.forEach(lang => {
        if (!change[lang]) {
          change[lang] = {};
        }
        change[lang][k] = v[lang];
      });
    }
    this.props.onNewData(change);
    this.setState({
      newData: change
    })
  }

  isVersionLowerOrEqual(element_version, app_version) {
    element_version = element_version.split('.').map(function (el) { return parseInt(el) });
    app_version = app_version.split('.').map(function (el) { return parseInt(el) });
    for (var i = 0; i < element_version.length; i++) {
      //console.log("---->>>> ---->>>> %s < %s", element_version[i], app_version[i])
      if (element_version[i] < app_version[i] && i + 1 < element_version.length) {
        return true;
      }
      if (element_version[i] <= app_version[i] && i + 1 === element_version.length) {
        return true;
      }
      if (element_version[i] > app_version[i]) {
        return false;
      }
    }
    return false;
  };

  renderFields(fieldsList) {
    //console.log('......... fields', fieldsList);
    if (fieldsList) {
      return fieldsList.map((element, index) => {
        if (this.isVersionLowerOrEqual(element.version, this.props.selectedVersion)) {
          return (
            <I18nElement key={element.key} field={element} value={this.getFieldValue(element.key)}
              languages={this.props.data.configs.availableLanguages} onChange={this.handleChange.bind(this)}>
            </I18nElement>
          );
        }
        return "";
      });
    }
  }

  renderlanguageHeaders() {
    if (this.props.data.configs && this.props.data.configs.availableLanguages) {
      return this.props.data.configs.availableLanguages.map((element) => {
        return (<th key={element}>{element}</th>)
      })
    }
  }

  toggle() {
    this.setState(
      {
        tableCollapse: !this.state.tableCollapse,
        collapseIcon: this.state.collapseIcon === '+' ? '-' : '+'
      }
    );
  }

  render() {
    //console.log('::::::::::: element: %o', this.props.element);
    return (
      <div className="i18nBlock" >
        <div className="title" onClick={this.toggle.bind(this)}>
          <div className="collapseBtn">{this.state.collapseIcon}</div>
          <b>{this.props.element.blockTitle}</b> - {this.props.element.blockDescription}
        </div>
        <Collapse isOpen={!this.state.tableCollapse}>
          <Table hover size="sm">
            <thead>
              <tr className="text-center">
                <th>Key</th>
                {this.renderlanguageHeaders()}
              </tr>
            </thead>
            <tbody>
              {this.renderFields(this.props.element.fields)}
            </tbody>
          </Table>
        </Collapse>
      </div>
    );
  }
}

export default I18nBlock;