const customerReducer = (state = {
  loading: false,
  getDetailsResult: null,
  getDetailsError: null
}, action) => {
  switch (action.type) {
    case "MBG_CUSTOMER_GET_DETAILS":
      state = {
        ...state,
        loading: true,
        getDetailsError: null,
        getDetailsResult: null
      };
      break;
    case "MBG_CUSTOMER_GET_DETAILS_SUCCESS":
      state = {
        ...state,
        loading: false,
        getDetailsResult: action.payload
      };
      break;
    case "MBG_CUSTOMER_GET_DETAILS_FAILURE":
      state = {
        ...state,
        loading: false,
        getDetailsError: action.payload
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default customerReducer;
