import React from "react";
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import HomeContainer from "./home";
import ZeroContainer from "./zero/index";
import LoginContainer from "./login";
import NotFoundContainer from "./404";
import HeaderContainer from "./header";
/*apiv2*/
import AddonsContainer from "./apiv2/addons";
import CarClubContainer from "./apiv2/carclub";
import CarsContainer from "./apiv2/cars";
import ConfigurationsContainer from "./apiv2/configurations";
import CustomersContainer from "./apiv2/customers";
import IdentificationContainer from "./apiv2/identification";
import NotificationsContainer from "./apiv2/notifications";
import ReservationsContainer from "./apiv2/reservations";
import TripsContainer from "./apiv2/trips";
import Loader from "../Components/loader";
import I18nValidatorContainer from "./tools/i18n";
import RawRemoteConfigsContainer from "./tools/rawRc";
import RemoteConfigContainer from "./remoteConfig/dashboard";
import RemoteConfig2Container from "./remoteConfig/dashboardv2";
import PayfortContainer from "./tools/payfort";
import MvpContainer from "./tools/mvp";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { defaultPage: '/' };
  }
  
  render() {
    return (
      <BrowserRouter>
        <div>
          <HeaderContainer />
          <Container fluid>
            <Loader />
            <Switch>
              <Route
                path="/"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <RemoteConfigContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/configsv2"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <RemoteConfig2Container />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/home"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <HomeContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/zero"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <ZeroContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/addons"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <AddonsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/carclub"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <CarClubContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/cars"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <CarsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/configurations"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <ConfigurationsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/customers"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <CustomersContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/identification"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <IdentificationContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/notifications"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <NotificationsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/reservations"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <ReservationsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/trips"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <TripsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/i18n"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <I18nValidatorContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/payfort"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <PayfortContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/rawrc"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <RawRemoteConfigsContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/mvp"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <MvpContainer />
                  ) : (
                      <Redirect to="/login" />
                    )
                }
              />
              <Route
                path="/login"
                exact
                render={() =>
                  this.props.loggedIn ? <Redirect to={this.state.defaultPage} /> : <LoginContainer />
                }
              />
              <Route component={NotFoundContainer} />
            </Switch>
          </Container>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn
  }
};


export default connect(mapStateToProps)(App);
