import React, { Component } from "react";
import { connect } from "react-redux";
import { getReservations, createReservation } from "../../Actions/reservationsActions";
import {
  Button, Form, FormGroup, Label, Input
} from "reactstrap";
import mobiagApi from "../../api/mobiag-api";
import MethodBox from "../../Components/MethodBox";

class ReservationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationStartDate: '',
      reservationEndDate: ''
    };
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage();
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  // componentWillUnmount() {
  //   window.removeEventListener(
  //     "beforeunload",
  //     this.saveStateToLocalStorage.bind(this)
  //   );

  //   // saves if component has a chance to unmount
  //   this.saveStateToLocalStorage();
  // }

  hydrateStateWithLocalStorage() {
    if (localStorage.hasOwnProperty('page-state-reservations')) {
      let stg = localStorage.getItem('page-state-reservations');
      console.log(stg);
      try {
        stg = JSON.parse(stg);
        for (let key in this.state) {
          console.log("key: %o | value: %o", key, stg[key]);
          this.setState({ [key]: stg[key] });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page-state-reservations', JSON.stringify(this.state));
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  refreshFields() {
    console.log('TO DO');
  }

  createReservation() {
    let body = {
      // "addons_code": [
      //   "string"
      // ],
      // "brand_code": 0,
      // "canceled": true,
      // "canceled_user": "string",
      // "carAvailable": true,
      // "carCheck": true,
      // "carToken": "string",
      // "car_code": "string",
      // "carclubCode": "string",
      // "cargroup_code": 0,
      // "changeDatetime": "2019-01-22T14:11:03.807Z",
      // "changeUsername": "string",
      // "class_code": 0,
      // "closed": true,
      // "code": "string",
      // "createDatetime": "2019-01-22T14:11:03.807Z",
      // "createUsername": "string",
      // "createdByCallCenter": true,
      // "customerAuthorized": true,
      // "customerCheck": true,
      // "customerCode": "string",
      // "customer_id": "string",
      // "customer_pin": 0,
      // "customer_requirement": "string",
      // "dateManuallySet": true,
      "endDate": mobiagApi.formatDate2(new Date(this.state.reservationEndDate)),
      // "endLatitude": 0,
      // "endLogitude": 0,
      // "endZone": "string",
      // "end_time": "string",
      // "expired": true,
      // "extended": true,
      // "extendedByCallCenter": true,
      // "group_id": "string",
      // "id": "string",
      // "internalReservation": true,
      // "invoiced": true,
      // "lastVersion": "string",
      // "location": "string",
      // "model_code": 0,
      // "price": 0,
      // "readyToStart": true,
      // "requestCarBrand": 0,
      // "requestCarClassCode": "string",
      // "requestCarGroup": "string",
      // "requestCarModelCode": 0,
      // "requestCarPlate": "string",
      // "requestCustomer": "string",
      // "requestEndDate": "2019-01-22T14:11:03.807Z",
      // "requestEndZone": "string",
      // "requestGroup": "string",
      // "requestLocation": "string",
      // "requestPrice": 0,
      // "requestStartDate": "2019-01-22T14:11:03.807Z",
      // "requestStartZone": "string",
      // "requestUser": "string",
      "startDate": mobiagApi.formatDate2(new Date(this.state.reservationStartDate)),
      // "startLatitude": 0,
      // "startLongitude": 0,
      // "startZone": "string",
      // "start_time": "string",
      // "tripEnded": true,
      // "tripStarted": true,
      // "version": "string",
      // "zoneCheck": true
    }
    this.props.createReservation(body);
  }

  render() {
    return <div>
      <br />
      <h5>RESERVATIONS</h5>
      <hr />
      <MethodBox title="Get Reservations" result={this.props.getReservationsResult} error={this.props.getReservationsError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.getReservations()}>Submit</Button>
      </MethodBox>
      <MethodBox title="Create Reservation" result={this.props.createReservationResult} error={this.props.createReservationError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.refreshFields()}>Populate fields</Button>
        <hr />
        <Form>
          <FormGroup>
            <Label for="reservationStartDate" size="sm">Start Date</Label>
            <Input
              type="date"
              name="reservationStartDate"
              bsSize="sm"
              value={this.state.reservationStartDate}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="reservationEndDate" size="sm">End Date</Label>
            <Input
              type="date"
              name="reservationEndDate"
              bsSize="sm"
              value={this.state.reservationEndDate}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
        </Form>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.createReservation()}>Submit</Button>
      </MethodBox>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.reservationsReducer.loading,
    getReservationsResult: state.reservationsReducer.getReservationsResult,
    getReservationsError: state.reservationsReducer.getReservationsError,
    createReservationResult: state.reservationsReducer.createReservationResult,
    createReservationError: state.reservationsReducer.createReservationError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReservations: () => {
      getReservations(dispatch);
    },
    createReservation: (body) => {
      createReservation(body, dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsContainer);
