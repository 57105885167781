import React, { Component } from "react";
import { connect } from "react-redux";

class CarClubContainer extends Component {
  render() {
    return <div>
      <br />
      <h5>CarClub</h5>
      <hr/>

    </div>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CarClubContainer);
