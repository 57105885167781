import {
	ZERO_CONNECTION,
	ZERO_CONNECTION_SUCCESS,
	ZERO_CONNECTION_FAILURE,
} from '../Actions/zeroActionsTypes';

const initState = {
  loading: false,
  zeroConnectionError: null,
  connected: false
}

const zeroReducer = (state = initState, action) => {
  switch (action.type) {

    case ZERO_CONNECTION:
      state = {
        ...state,
        loading: true,
        zeroConnectionError: null,
        connected: false
      };
      break;

    case ZERO_CONNECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        connected: true
      };
      break;

    case ZERO_CONNECTION_FAILURE:
      state = {
        ...state,
        loading: false,
        connected: false,
        zeroConnectionError: action.payload
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default zeroReducer;
