import React, { Component } from "react";
import { connect } from "react-redux";
import { createApp, getApps, getAppById } from "../../Actions/configurationActions";
import {
  Button, Form, FormGroup, Label, Input
} from "reactstrap";
import MethodBox from "../../Components/MethodBox";

class ConfigurationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: '',
      appVersion: '',
      appConfig: ''
    };
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage();
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  // componentWillUnmount() {
  //   window.removeEventListener(
  //     "beforeunload",
  //     this.saveStateToLocalStorage.bind(this)
  //   );

  //   // saves if component has a chance to unmount
  //   this.saveStateToLocalStorage();
  // }

  hydrateStateWithLocalStorage() {
    if (localStorage.hasOwnProperty('page-state-configurations')) {
      let stg = localStorage.getItem('page-state-configurations');
      console.log(stg);
      try {
        stg = JSON.parse(stg);
        for (let key in this.state) {
          console.log("key: %o | value: %o", key, stg[key]);
          this.setState({ [key]: stg[key] });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page-state-configurations', JSON.stringify(this.state));
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  render() {
    return <div>
      <br />
      <h5>Configurations</h5>
      <hr />
      <MethodBox title="Create App" result={this.props.createAppResult} error={this.props.createAppError}>
        <Form>
          <FormGroup>
            <Label for="appName" size="sm">App Name</Label>
            <Input
              type="text"
              name="appName"
              bsSize="sm"
              value={this.state.appName}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="appVersion" size="sm">Version</Label>
            <Input
              type="text"
              name="appVersion"
              bsSize="sm"
              value={this.state.appVersion}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="appConfig" size="sm">Config</Label>
            <Input
              type="textarea"
              name="appConfig"
              bsSize="sm"
              value={this.state.appConfig}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
        </Form>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.createApp(this.state.appName, this.state.appConfig, this.state.appVersion)}>Submit</Button>
      </MethodBox>
      <MethodBox title="Get Apps" result={this.props.getAppsResult} error={this.props.getAppsError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.getApps()}>Submit</Button>
      </MethodBox>
      <MethodBox title="Get App By Id" result={this.props.getAppByIdResult} error={this.props.getAppByIdError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.getAppById()}>Submit</Button>
      </MethodBox>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.configurationsReducer.loading,
    error: state.configurationsReducer.error,
    createAppResult: state.configurationsReducer.createAppResult,
    createAppError: state.configurationsReducer.createAppError,
    getAppsError: state.configurationsReducer.getAppsError,
    getAppsResult: state.configurationsReducer.getAppsResult,
    getAppByIdError: state.configurationsReducer.getAppByIdError,
    getAppByIdResult: state.configurationsReducer.getAppByIdResult
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createApp: (appName, configuration, version) => {
      createApp(appName, configuration, version, dispatch);
    },
    getApps: () => {
      getApps(dispatch);
    },
    getAppById: () => {
      getAppById('id', dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsContainer);
