import React from "react";
import { Row, Col, Table } from "reactstrap";
import ConfigInput from "./ConfigInput";

class ConfigsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      newData: props.newData || {}
    };
  }

  getFieldValue(key) {
    if (this.state.newData && key in this.state.newData) {
      return this.state.newData[key];
    }
    if (this.props.data) {
      return this.props.data[key];
    }
  }

  handleChange(k, v) {
    console.log('~~   ~~~ ~~ key: %o, value: %o', k, v);
    let change = this.state.newData;
    // if (JSON.stringify(v) === JSON.stringify(this.props.data[k])) {
    //   delete change[k];
    // }
    // else {
    //   change[k] = v;
    // }
    change[k] = v;
    //console.log('change: |%s|', JSON.stringify(change));
    this.props.onNewData(change);
    if (JSON.stringify(v) === JSON.stringify(this.props.data[k])) {
      delete change[k];
    }
    this.setState({
      newData: change
    })
  }

  isVersionLowerOrEqual(element_version, app_version) {
    element_version = element_version.split('.').map(function (el) { return parseInt(el) });
    app_version = app_version.split('.').map(function (el) { return parseInt(el) });
    for (var i = 0; i < element_version.length; i++) {
      //console.log("---->>>> ---->>>> %s < %s", element_version[i], app_version[i])
      if (element_version[i] < app_version[i] && i + 1 < element_version.length) {
        return true;
      }
      if (element_version[i] <= app_version[i] && i + 1 === element_version.length) {
        return true;
      }
      if (element_version[i] > app_version[i]) {
        return false;
      }
    }
    return false;
  };

  renderFields() {
    if (this.props.fields) {
      return this.props.fields.map((element, index) => {
        if (this.isVersionLowerOrEqual(element.version, this.props.selectedVersion)) {
          return (<ConfigInput field={element} value={this.getFieldValue(element.key)} key={element.key} onChange={this.handleChange.bind(this)}></ConfigInput>);
        }
        return "";
      });
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={{ size: 12 }}>
            <br />
            <Table hover size="sm">
              <thead>
                <tr className="text-center">
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {this.renderFields()}
              </tbody>
            </Table>

          </Col>
          {/* <Col md={{ size: 4 }}>
            <br />
            {
              this.props.changesToUpdate && this.props.showChangesBox ?
                (<Card className="card-json" color="primary">
                  <CardHeader>New Data</CardHeader>
                  <CardFooter>
                    <JsonToHtml object={this.state.newData}></JsonToHtml>
                  </CardFooter>
                </Card>) : ""
            }
            <br />
            <Card className="card-json">
              <CardHeader>Original Data</CardHeader>
              <CardFooter>
                <JsonToHtml object={this.props.data}></JsonToHtml>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
      </div>
    );
  }
}

export default ConfigsTab;