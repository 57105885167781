import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, FormGroup, Input, Label, Row, ButtonGroup, Button, Spinner, Alert } from "reactstrap";

import { getConfigs, addRemoteConfig } from "../../Actions/remoteConfigsActions";
import JsonToHtml from "../../Components/JsonToHtml";
import AppSelector from "../../Components/remoteConfig/dashboard/appSelector/AppSelector";
import ConfigsZone from "../../Components/remoteConfig/dashboard/configsZone/ConfigsZone";
import I18nZone from "../../Components/remoteConfig/dashboard/i18nZone/I18nZone";
import UpdateModal from "../../Components/remoteConfig/dashboard/update/updateModal";

const apps = require("../../data/remoteconfig/appsv2.json");
const i18n = require("../../data/remoteconfig/i18nv2.json");
const configs = require("../../data/remoteconfig/configsv2.json");

class RemoteConfig2Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApp: {},
      selectedVersion: "",
      rSelected: 1,
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false
    }
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    window.rc = this;
    this.updateModalRef = React.createRef();
  }

  componentDidUpdate() {
    //console.log('####################################################');
    //console.log(this.state);
    this.refreshNewConfigs();
    this.refreshNewI18n();
  }

  onChangeApp(app) {
    this.setState({
      selectedApp: app,
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false
    });
    let version = this.state.selectedVersion;
    if (!version || app.versions.indexOf(version) < 0) {
      version = app.versions[0];
      this.setState({
        selectedVersion: version
      })
    }
    this.props.getConfigs(app.app_name, version);
  }

  onChangeVersion(key) {
    this.setState({
      selectedVersion: key.target.value,
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false
    });
    this.props.getConfigs(this.state.selectedApp.app_name, key.target.value);
  }

  onChangeConfigs(data) {
    //console.log("DASHBOARD --- new data from configs: %o", data);
    this.setState(
      {
        configChanges: data,
        changesToUpdate: (JSON.stringify(data) !== "{}") || (JSON.stringify(this.state.i18nChanges) !== "{}")
      }
    );
  }

  onChangeI18n(data) {
    //console.log("DASHBOARD --- new data from i18n: %o", data);
    this.setState(
      {
        i18nChanges: data,
        changesToUpdate: (JSON.stringify(this.state.configChanges) !== "{}") || (JSON.stringify(data) !== "{}")
      }
    );
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  updateConfigs() {
    this.updateModalRef.current.open();
  }

  onUpdateConfirm() {
    console.log('this.props.getConfigsResult: %o | this.state.configChanges: %o | this.state.i18nChanges: %o', this.props.getConfigsResult, this.state.configChanges, this.state.i18nChanges);
    let configuration = this.props.getConfigsResult.configuration;
    configuration.configs = { ...configuration.configs, ...this.state.configChanges }
    for (let l in this.state.i18nChanges) {
      if (!configuration.i18n) {
        configuration.i18n = {};
      }
      configuration.i18n[l] = { ...configuration.i18n[l], ...this.state.i18nChanges[l] }
    }
    // configuration.i18n = { ...configuration.i18n, ...this.state.i18nChanges }
    // let extraConfigs = {
    //   mandatoryRegFields: [
    //     'firstName',
    //     'email',
    //     'password',
    //     'passwordConfirm',
    //     'pin',
    //     'taxNumber',
    //     'checkTerms',
    //     'idCard.expiration',
    //     'idCard.emission',
    //     'google-address',
    //     'payment.cardName',
    //     'payment.address',
    //     'payment.zip',
    //     'payment.city'
    //   ],
    //   lockRegFields: [
    //     'email'
    //   ],
    //   showRegFields: [
    //     'google-address',
    //     'gaAddress',
    //     'gaCountry',
    //     'gaState',
    //     'gaCity',
    //     'gaNeighborhood',
    //     'gaPostalCode',
    //     'gaNumber',
    //     'gaFloor',
    //     'firstName',
    //     'middleName',
    //     'surname',
    //     'email',
    //     'password',
    //     'passwordConfirm',
    //     'gender',
    //     'dateOfBirth',
    //     'homeMobile',
    //     'homePhone',
    //     'pin',
    //     'taxNumber',
    //     'checkTerms',
    //     'checkLicense',
    //     'checkAge',
    //     'idCard',
    //     'idCard.number',
    //     'idCard.expiration',
    //     'idCard.emission',
    //     'idCard.front',
    //     'idCard.back',
    //     'payment.cardName',
    //     'payment.address',
    //     'payment.zip',
    //     'payment.city'
    //   ],
    //   showAccountFields: [
    //     'google-address',
    //     'gaAddress',
    //     'gaCountry',
    //     'gaState',
    //     'gaCity',
    //     'gaNeighborhood',
    //     'gaPostalCode',
    //     'gaNumber',
    //     'gaFloor',
    //     'firstName',
    //     'middleName',
    //     'surname',
    //     'email',
    //     'password',
    //     'passwordConfirm',
    //     'gender',
    //     'dateOfBirth',
    //     'homeMobile',
    //     'homePhone',
    //     'pin',
    //     'taxNumber',
    //     'checkTerms',
    //     'checkLicense',
    //     'checkAge',
    //     'idCard',
    //     'idCard.number',
    //     'idCard.expiration',
    //     'idCard.emission',
    //     'idCard.front',
    //     'idCard.back',
    //     'payment.cardName',
    //     'payment.address',
    //     'payment.zip',
    //     'payment.city'
    //   ]
    // }
    // configuration.configs = { ...configuration.configs, ...extraConfigs};
    //console.log('CONFIGS TO UPDATE: %o', configuration);
    this.props.addRemoteConfig(this.state.selectedApp.app_name, this.state.selectedVersion, configuration);

    //CLEAN START
    // configuration = {
    //   configs: {
    //     enableEndTripPhotos: false
    //   }
    // }
    //this.props.addRemoteConfig("Mango", "2.4", configuration);
  }

  refreshNewConfigs() {
    //console.log('refreshNewConfigs - this.state.configChanges: %s', JSON.stringify(this.state.configChanges));
    let configChanges = this.state.configChanges;
    let update = false;
    for (var k in configChanges) {
      if (k === "showRegFields" || k === "mandatoryRegFields" || k === "showAccountFields" || k === "lockRegFields") {
        break;
      }
      if (this.props.getConfigsResult && this.props.getConfigsResult.configuration && configChanges[k] === this.props.getConfigsResult.configuration.configs[k]) {
        //console.log('deleting... %s, %s === %s', k, JSON.stringify(configChanges[k]), JSON.stringify(this.props.getConfigsResult.configuration.configs[k]));
        delete configChanges[k];
        update = true;
      }
    }
    if (update) {
      this.setState(
        {
          configChanges: configChanges,
          changesToUpdate: (JSON.stringify(configChanges) !== "{}") || (JSON.stringify(this.state.i18nChanges) !== "{}")
        }
      );
    }
  }

  refreshNewI18n() {
    let i18nChanges = this.state.i18nChanges;
    let update = false;
    for (var l in i18nChanges) {
      for (var k in i18nChanges[l]) {
        if (this.props.getConfigsResult && this.props.getConfigsResult.configuration && this.props.getConfigsResult.configuration && this.props.getConfigsResult.configuration.i18n && this.props.getConfigsResult.configuration.i18n[l] && i18nChanges[l][k] === this.props.getConfigsResult.configuration.i18n[l][k]) {
          delete i18nChanges[l][k];
          update = true;
        }
      }
    }
    if (update) {
      this.setState(
        {
          i18nChanges: i18nChanges,
          changesToUpdate: (JSON.stringify(i18nChanges) !== "{}") || (JSON.stringify(this.state.configChanges) !== "{}")
        }
      );
    }
  }

  renderLoadedStatus() {
    if (this.props.loading) {
      return (<div className="load-status"><Spinner size="sm" color="primary" /></div>);
    }
    else {
      if (this.props.getConfigsResult && this.props.getConfigsResult.app_configuration_id) {
        return (<div className="load-status"><Alert color="success">Config Loaded ({this.props.getConfigsResult.app_configuration_id})</Alert></div>);
      }
      else if (this.props.getConfigsError) {
        return (<div className="load-status"><Alert color="danger">{this.props.getConfigsError}</Alert></div>)
      }
    }
    return "";
  }

  renderUpdateButton() {
    let color = 'primary';
    let disabled = false;
    let text = "Update";
    if (!this.state.changesToUpdate) {
      color = 'secondary';
      disabled = true;
      text = "No changes";
    }
    if (this.props.loading) {
      disabled = true;
    }
    return (<Button color={color} disabled={disabled} size="sm" onClick={this.updateConfigs.bind(this)} >{text}</Button>);
  }

  renderHeader() {
    let outHtml = "";
    if (this.state.selectedApp && this.state.selectedApp.app_name) {
      outHtml = (
        <div>
          <Row>
            <Col md={{ size: 4 }}>
              <FormGroup row>
                <Label for="version" sm={6} size="sm">{this.state.selectedApp.app_name}</Label>
                <Col sm={6}>
                  <Input type="select" name="version" id="version" bsSize="sm" onChange={this.onChangeVersion.bind(this)}>
                    {this.state.selectedApp.versions.map((version) => { return (<option key={version} value={version}>{version}</option>) })}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md={{ size: 8 }}>
              <div className="float-right">
                {this.renderUpdateButton()}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonGroup>
                <Button color="secondary" size="sm" onClick={() => this.onRadioBtnClick(1)} active={this.state.rSelected === 1}>Configs</Button>
                <Button color="secondary" size="sm" onClick={() => this.onRadioBtnClick(2)} active={this.state.rSelected === 2}>I18N</Button>
                <Button color="secondary" size="sm" onClick={() => this.onRadioBtnClick(3)} active={this.state.rSelected === 3}>original (raw)</Button>
              </ButtonGroup>
              {this.renderLoadedStatus()}
            </Col>
          </Row>
          <hr />
        </div>
      );
    }
    else {
      outHtml = (
        <div>
          "Select an app to start :)"
          <hr />
        </div>
      );
    }
    return outHtml;
  }

  renderBody() {
    if (this.state.selectedApp && this.state.selectedApp.app_name) {
      switch (this.state.rSelected) {
        case 1:
          return (<ConfigsZone configs={configs} data={this.props.getConfigsResult} selectedVersion={this.state.selectedVersion} changesToUpdate={this.state.changesToUpdate} changes={this.state.configChanges} onChange={this.onChangeConfigs.bind(this)}></ConfigsZone>);
        case 2:
          return (<I18nZone i18n={i18n} data={this.props.getConfigsResult} selectedVersion={this.state.selectedVersion} changes={this.state.i18nChanges} onChange={this.onChangeI18n.bind(this)}></I18nZone>);
        case 3:
          return (
            <div>
              <br />
              Result:
              <JsonToHtml object={this.props.getConfigsResult} />
              <br />
              New config changes:
              <JsonToHtml object={this.state.configChanges} />
              <br />
              New i18n changes:
              <JsonToHtml object={this.state.i18nChanges} />
            </div>
          );

        default:
          return (<div>[body]</div>);
      }
    }
  }

  render() {
    return <div>
      <br />
      <h5>REMOTE CONFIGS</h5>
      <hr />
      <Container fluid>
        <Row>
          <Col md={{ size: 2 }}>
            <AppSelector apps={apps} onChange={this.onChangeApp.bind(this)}></AppSelector>
          </Col>
          <Col md={{ size: 10 }}>

            {/* HEADER */}
            {this.renderHeader()}
            {this.renderBody()}
          </Col>
        </Row>
      </Container>
      <UpdateModal ref={this.updateModalRef} app={this.state.selectedApp} version={this.state.selectedVersion} onConfirm={this.onUpdateConfirm.bind(this)}
        configChanges={this.state.configChanges} i18nChanges={this.state.i18nChanges} ></UpdateModal>

    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.remoteConfigsReducer.loading,
    getConfigsResult: state.remoteConfigsReducer.getConfigsResult,
    getConfigsError: state.remoteConfigsReducer.getConfigsError,
    createConfigResult: state.remoteConfigsReducer.createConfigResult,
    createConfigError: state.remoteConfigsReducer.createConfigError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfigs: (app_name, app_version) => {
      getConfigs(app_name, app_version, dispatch);
    },
    addRemoteConfig: (app_name, app_version, app_config) => {
      addRemoteConfig(app_name, app_version, app_config, dispatch);
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfig2Container);
