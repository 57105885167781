import React from "react";
import { Navbar, Nav, NavItem, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../Actions/authActions";
import { NavLink } from "react-router-dom";

class HeaderContainer extends React.Component {
  handleLogout() {
    this.props.onLogout();
  }
  render() {
    let sessionLink = "";
    if (this.props.loggedIn) {
      sessionLink = (
        <NavItem>
          <Button color="primary" onClick={() => { this.props.logout() }}>
            Logout
          </Button>
        </NavItem>
      );
    }
    let mainMenu = "";
    if (this.props.loggedIn) {
      mainMenu = (
        <Nav navbar>
          <NavItem>
            <NavLink className="nav-link" to="/">Home</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink className="nav-link" to="/zero">Zero</NavLink>
          </NavItem> */}
          {/* <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              API V2
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink to="/addons">AddOns</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/carclub">CarClub</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/cars">Cars</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/configurations">Configurations</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/customers">Customers</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/identification">Identification</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/notifications">Notifications</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/reservations">Reservations</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/trips">Trips</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              TOOLS
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink to="/i18n">i18n</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/mvp">Mvp</NavLink>
              </DropdownItem>
              {/* <DropdownItem>
                <NavLink to="/payfort">Payfort</NavLink>
              </DropdownItem> */}
              <DropdownItem>
                <NavLink to="/rawrc">RC tools</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      );
    }
    return (
      <Navbar className="mainNav" expand="md">
        <Container>
          {mainMenu}
          <Nav className="ml-auto" navbar>
            {sessionLink}
          </Nav>
        </Container>
      </Navbar>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    loggedUserEmail: state.authReducer.loggedUserEmail,
    loggedUserEnvironment: state.authReducer.loggedUserEnvironment
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      logout(dispatch)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
