import {
  LOCATIONS_GET,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATIONS_ASSETS,
  LOCATIONS_ASSETS_SUCCESS,
  LOCATIONS_ASSETS_FAILURE,
} from './locationActionsTypes';
import zero from "../../api/zero-websocket";

export const getLocations = (dispatch) => {
  dispatch({ type: LOCATIONS_GET });
  zero.publishWithCallback('LOCATION', 'GET', undefined, (result) => {
    if (!result.error) {
      dispatch({ type: LOCATIONS_GET_SUCCESS, payload: result.processMessage });
    }
    else {
      dispatch({ type: LOCATIONS_GET_FAILURE, payload: result.processMessage });
    }
  });
}

export const getLocationAssets = (locationId, dispatch) => {
  dispatch({ type: LOCATIONS_ASSETS });
  zero.publishWithCallback('LOCATION', 'ASSETS', { locationId }, (result) => {
    if (!result.error) {
      dispatch({ type: LOCATIONS_ASSETS_SUCCESS, payload: result.processMessage });
    }
    else {
      dispatch({ type: LOCATIONS_ASSETS_FAILURE, payload: result.processMessage });
    }
  });
}