import React, { Component } from "react";
import { connect } from "react-redux";
import zero from "../api/zero-websocket";

class HomeContainer extends Component {

  handleClick() {
    zero.publishWithCallback('LOCATION', 'GET', undefined, (result) => console.log('::: %o', result));
  }

  render() {
    return <div>
      <br />
      <h5>HOME</h5>
      <hr/>
      <div onClick={this.handleClick.bind(this)}>TEST</div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
