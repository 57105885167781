import React from "react";
import {
  Row, Col
} from "reactstrap";
import SignUpItem from "../customer/SignUpItem";
import JsonToHtml from "../../../JsonToHtml";

const fields = require("../../../../data/remoteconfig/signup/fields.json");

class ConfigsCustomerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      output: {},
      newData: props.newData || {}
    }
  }

  componentDidMount() {
    let x = {};
    x.showRegFields = this.props.data.showRegFields;
    x.mandatoryRegFields = this.props.data.mandatoryRegFields;
    x.showAccountFields = this.props.data.showAccountFields;
    x.lockRegFields = this.props.data.lockRegFields;
    this.setState({
      output: x
    })
  }

  handleChange(key, obj) {
    //console.log('key: %o, obj: %o', key, obj);
    this.processOutput(key, obj);
  }

  processOutput(key, obj) {
    //show_reg: false, mandatory: false, show_account: true, locked: false
    let output = this.state.output;
    let change = this.state.newData;
    if (obj.show_reg) {
      output.showRegFields = this.addTo(output.showRegFields, key);
    }
    else {
      output.showRegFields = this.removeFrom(output.showRegFields, key);
    }
    if (obj.mandatory) {
      output.mandatoryRegFields = this.addTo(output.mandatoryRegFields, key);
    }
    else {
      output.mandatoryRegFields = this.removeFrom(output.mandatoryRegFields, key);
    }
    if (obj.show_account) {
      output.showAccountFields = this.addTo(output.showAccountFields, key);
    }
    else {
      output.showAccountFields = this.removeFrom(output.showAccountFields, key);
    }
    if (obj.locked) {
      output.lockRegFields = this.addTo(output.lockRegFields, key);
    }
    else {
      output.lockRegFields = this.removeFrom(output.lockRegFields, key);
    }
    change['showRegFields'] = output['showRegFields'];
    change['mandatoryRegFields'] = output['mandatoryRegFields'];
    change['showAccountFields'] = output['showAccountFields'];
    change['lockRegFields'] = output['lockRegFields'];
    this.props.onNewData(change);
    this.setState({
      output: output
    })
  }

  removeFrom(list, item) {
    //console.log('remove - list: %o, item: %o', list, item);
    if (!list) {
      list = [];
    }
    var index = list.indexOf(item);
    if (index !== -1) {
      list.splice(index, 1);
    }
    return list.sort();
  }

  addTo(list, item) {
    //console.log('add - list: %o, item: %o', list, item);
    if (!list) {
      list = [];
    }
    if (!list.find((x) => x === item)) {
      list.push(item);
    }
    return list.sort();
  }

  renderSignUpItem(list) {
    let outHtml = "";
    if (list) {
      outHtml = list.map((item) => {
        return (<SignUpItem title={item.title} key={item.key} id={item.key} onChange={this.handleChange.bind(this)} checked={this.getItemValues(item.key)}></SignUpItem>)
      })
    }
    return outHtml;
  }

  getItemValues(key) {
    let values = [false, false, false, false];
    if (this.props.data) {
      if (this.props.data.showRegFields) {
        values[0] = this.props.data.showRegFields.indexOf(key) >= 0;
      }
      if (this.props.data.mandatoryRegFields) {
        values[1] = this.props.data.mandatoryRegFields.indexOf(key) >= 0;
      }
      if (this.props.data.showAccountFields) {
        values[2] = this.props.data.showAccountFields.indexOf(key) >= 0;
      }
      if (this.props.data.lockRegFields) {
        values[3] = this.props.data.lockRegFields.indexOf(key) >= 0;
      }
    }
    return values;
  }

  render() {
    let signupHtml = "";
    let persinfoHtml = "";
    let documentationHtml = "";
    let paymentHtml = "";
    if (fields) {
      signupHtml = this.renderSignUpItem(fields.signup);
      persinfoHtml = this.renderSignUpItem(fields.personal_info);
      documentationHtml = this.renderSignUpItem(fields.documentation);
      paymentHtml = this.renderSignUpItem(fields.payment);
    }
    return (
      <div>
        <Row form>
          <Col md={8}>
            <h5>Sign Up</h5>
            <hr />
            {signupHtml}
            <br />
            <h5>Personal Information</h5>
            <hr />
            {persinfoHtml}
            <br />
            <h5>Documentation</h5>
            <hr />
            {documentationHtml}
            <br />
            <h5>Payment</h5>
            <hr />
            {paymentHtml}
            <br />
          </Col>
          <Col md={4}>
            <JsonToHtml object={this.state.output} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConfigsCustomerTab;