const reservationsReducer = (state = {
  loading: false,
  getReservationsResult: null,
  getReservationsError: null,
  createReservationResult: null,
  createReservationError: null
}, action) => {
  switch (action.type) {
    case "MBG_RESERVATIONS_GET_RESERVATION":
      state = {
        ...state,
        loading: true,
        getReservationsError: null,
        getReservationsResult: null
      };
      break;
    case "MBG_RESERVATIONS_GET_RESERVATION_SUCCESS":
      state = {
        ...state,
        loading: false,
        getReservationsResult: action.payload
      };
      break;
    case "MBG_RESERVATIONS_GET_RESERVATION_FAILURE":
      state = {
        ...state,
        loading: false,
        getReservationsError: action.payload,
      };
      break;
      case "MBG_RESERVATIONS_CREATE_RESERVATION":
        state = {
          ...state,
          loading: true,
          createReservationError: null,
          createReservationResult: null
        };
        break;
      case "MBG_RESERVATIONS_CREATE_RESERVATION_SUCCESS":
        state = {
          ...state,
          loading: false,
          createReservationResult: action.payload
        };
        break;
      case "MBG_RESERVATIONS_CREATE_RESERVATION_FAILURE":
        state = {
          ...state,
          loading: false,
          createReservationError: action.payload,
        };
        break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default reservationsReducer;
