import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import authReducer from '../Store/Reducers/authReducer';
import phonegapReducer from '../Store/Reducers/phonegapReducer';
import configurationsReducer from '../Store/Reducers/configurationsReducer';
import carsReducer from '../Store/Reducers/carsReducer';
import customerReducer from '../Store/Reducers/customerReducer';
import reservationsReducer from '../Store/Reducers/reservationsReducer';
import remoteConfigsReducer from '../Store/Reducers/remoteConfigsReducer';
import zeroReducer from '../Store/Reducers/zeroReducer';
import locationsReducer from '../Store/Reducers/locationsReducer';

export default createStore(
  combineReducers({authReducer, phonegapReducer, configurationsReducer, carsReducer, customerReducer, reservationsReducer, remoteConfigsReducer, zeroReducer, locationsReducer}),
  {},
  applyMiddleware(createLogger(), thunk)
);