import axios from "axios";
import sha256 from 'crypto-js/sha256';

const mbgApiBehavior = {
  auth: function (apiUrl, username, encryptedPassword, callback) {
    let options = {
      method: 'post',
      url: apiUrl,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'cache-control': 'no-cache'
      },
      data: "username=" + username + "&password=" + sha256(new Date().getTime().toString().substring(0, 7) + encryptedPassword)
    };
    console.log('[AUTH] >>> options: %o', options);
    axios(options).then(
      function (response) {
        let tgtToken = response.data;
        console.log(tgtToken);
        // protection case response is in html
        if (tgtToken.indexOf('<html>') > -1) {
          tgtToken = tgtToken.substring(
            tgtToken.indexOf('TGT', 150),
            tgtToken.indexOf('"', 180)
          );
        }
        let options = {
          method: 'post',
          url: apiUrl + "/" + tgtToken,
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'cache-control': 'no-cache'
          },
          data: "service=mobicsApi"
        };
        console.log('[AUTH - TGT] >>> options: %o', options);
        axios(options).then(
          function (response) {
            console.log('[AUTH - ST] >>> result: %o', response.data);
            callback(response.data);
          }
        ).catch(
          function (error) {
            callback(null, error);
          }
        );
      }
    ).catch(
      function (error) {
        callback(null, error);
      }
    );
  },

  get: function (token, apiUrl, method, params, callback) {
    let url = apiUrl + '/' + method;
    if (params) {
      url += '?' + this._serializeToUrl(params);
    }
    let options = {
      method: 'get',
      url: url,
      headers: {
        'Content-type': 'application/json',
        'token': token
      }
    };
    console.log('[GET %s] >>> options: %o', method, options);
    axios(options).then(
      (response) => {
        console.log('[GET %s] (%o) <<< response: %o', method, response.status, response);
        callback(response);
      }
    ).catch(
      (error) => {
        if (error.response) {
          console.log('[GET %s] (%o) <<< error: %o', method, error.response.status, error.response.data);
        } else if (error.request) {
          console.log('[GET %s] <<< error: %o', method, error.request);
        } else {
          console.log('[GET %s] <<< error: %o', method, error.message);
        }
        if (callback) {
          callback(null, error);
        }
      }
    );
  },

  post: function (token, apiUrl, method, params, callback) {
    let url = apiUrl + '/' + method;
    let options = {
      method: 'post',
      url: url,
      headers: {
        'Content-type': 'application/json',
        'token': token
      }
    };
    if (params) {
      options.data = params;
    }
    console.log('[POST] >>> options: %o', options);
    axios(options).then(
      (response) => {
        console.log('[POST] <<< response: %o', response);
        callback(response);
      }
    ).catch(
      (error) => {
        if (error.response) {
          console.log('[POST] (%o) <<< error: %o', error.response.status, error.response.data);
        } else if (error.request) {
          console.log('[POST] <<< error: %o', error.request);
        } else {
          console.log('[POST] <<< error: %o', error.message);
        }
        callback(null, error);
      }
    );
  },

  _serializeToUrl: function (obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
}

export default mbgApiBehavior