import React, { Component } from "react";
import { connect } from "react-redux";
import { connectToZero, closeSocket } from "../../Store/Actions/zeroActions";
import { getLocations } from "../../Store/Actions/locationActions";
import { Button, Spinner, Alert, Row, Col } from "reactstrap";
import JsonToHtml from "../../Components/JsonToHtml";



class ZeroContainer extends Component {

  handleLoginWS() {
    console.log("LOGIN WS");
    const formData = new FormData();
    formData.append('username', 'user');
    formData.append('password', localStorage.getItem('authToken'));
    fetch(
      'http://54.77.61.58:8678/authenticate',
      {
        // credentials: 'include',
        method: 'post',
        // body: 'username=user,password=' + localStorage.getItem('authToken')
        body: formData,
        // crossDomain: true,
        // mode: "no-cors",
        // "Accept": "application/json",
        // headers: {
        //   'cache-control': 'no-cache',
        //   'pragma': 'no-cache'
        // }
      }
    ).then((resp) => {
      if (resp && resp.ok) {
        console.log("LOGIN WS Success: %o", resp)
      }
      else {
        console.log("LOGIN WS Not Success: %o", resp)
      }
    })
      .catch((error) => console.log("LOGIN WS Error: %o", error))
  }

  handleClick() {
    //zero.connect();
    this.props.connectToZero(localStorage.getItem('authToken'));
  }

  handleClickDisc() {
    this.props.closeSocket();
  }

  handleClick2() {
    this.props.getLocations();
    // zero.publishWithCallback('LOCATION', 'GET', undefined, (result) => console.log('::: %o', result));

    // const z = new ZeroWebSocket({ url: 'http://54.77.61.58:8678', path: '/gs-guide-websocket', token: localStorage.getItem('authToken'), debug: true });
    // z.connect();
    // z.onMessageSuccess((result) => console.log('::: %o', result));
    // z.onConnect(() => {
    //   z.publishWithCallback('LOCATION', 'GET', undefined, (result) => console.log('::: %o', result));
    // })
    // //z.test();
    // window.zero = z;
  }

  renderLoadedStatus() {
    if (this.props.loading) {
      return (<div className="load-status"><Spinner size="sm" color="primary" /></div>);
    }
    else {
      if (this.props.zeroConnectionError) {
        return (<div className="load-status"><Alert color="danger">{this.props.zeroConnectionError}</Alert></div>)
      }
      else if (this.props.websocketConnected) {
        return (<div className="load-status"><Alert color="success">Connected</Alert></div>);
      }
    }
    return "";
  }

  render() {
    return <div>
      <br />
      <h5>ZERO WEBSOCKETS</h5>
      <hr />
      <Row>
        <Col>
          <Button color='primary' size="sm" onClick={this.handleLoginWS.bind(this)}>LOGIN WS</Button>
          {this.renderLoadedStatus()}
          <br /><br />
          <Button color='primary' size="sm" onClick={this.handleClick.bind(this)}>CONNECT</Button>
          {this.renderLoadedStatus()}
          <br /><br />
          <Button color='primary' size="sm" onClick={this.handleClickDisc.bind(this)}>DISCONNECT</Button>
          <br /><br />
          <Button color='primary' size="sm" onClick={this.handleClick2.bind(this)}>GET LOCATIONS</Button>
          <br /><br />
          {/* <Button color='primary' size="sm" onClick={this.handleClickGetAssets.bind(this)}>GET ASSETS</Button> */}
        </Col>
        <Col>
          <JsonToHtml object={this.props.output} />
        </Col>
      </Row>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.zeroReducer.loading,
    websocketConnected: state.zeroReducer.connected,
    zeroConnectionError: state.zeroReducer.zeroConnectionError,
    output: state.locationsReducer.locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectToZero: (token) => {
      connectToZero(token, dispatch);
    },
    getLocations: () => {
      getLocations(dispatch);
    },
    closeSocket: () => {
      closeSocket(dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ZeroContainer);
