import {
	LOCATIONS_GET,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATIONS_ASSETS,
  LOCATIONS_ASSETS_SUCCESS,
  LOCATIONS_ASSETS_FAILURE,
} from '../Actions/locationActionsTypes';

const initState = {
  loading: false,
  locations: [],
  locationsError: null,
  assets: [],
  assetsError: null
}

const locationsReducer = (state = initState, action) => {
  switch (action.type) {

    case LOCATIONS_GET:
      state = {
        ...state,
        loading: true,
        locationsError: null
      };
      break;

    case LOCATIONS_GET_SUCCESS:
      state = {
        ...state,
        loading: false,
        locations: action.payload
      };
      break;

    case LOCATIONS_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        locationsError: action.payload
      };
      break;

      case LOCATIONS_ASSETS:
        state = {
          ...state,
          loading: true,
          locationsError: null,
          assets: []
        };
        break;
  
      case LOCATIONS_ASSETS_SUCCESS:
        state = {
          ...state,
          loading: false,
          assets: action.payload
        };
        break;
  
      case LOCATIONS_ASSETS_FAILURE:
        state = {
          ...state,
          loading: false,
          assetsError: action.payload
        };
        break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default locationsReducer;
