import {
	ZERO_CONNECTION,
	ZERO_CONNECTION_SUCCESS,
	ZERO_CONNECTION_FAILURE,
} from './zeroActionsTypes';
import zero from "../../api/zero-websocket";


export const connectToZero = (token, dispatch) => {
  dispatch({
    type: ZERO_CONNECTION
  });
  zero.connect(token);
  zero.onConnect(() => {
    dispatch({
      type: ZERO_CONNECTION_SUCCESS
    })
  })
  zero.onConnectError((error) => {
    dispatch({
      type: ZERO_CONNECTION_FAILURE,
      payload: error
    })
  })
}

export const closeSocket = (dispatch) => {
  zero.close();
  dispatch({
    type: ZERO_CONNECTION_FAILURE,
    payload: "DISCONNECTED"
  })
}