const phonegapReducer = (state = {
  loading: false,
  apps: [],
  error: false
}, action) => {
  switch (action.type) {
    case "PHONEGAP_GET_APPS":
      state = {
        ...state,
        loading: true,
        error: false
      };
      break;
    case "PHONEGAP_GET_APPS_SUCCESS":
      state = {
        ...state,
        loading: false,
        apps: action.payload
      };
      break;
    case "PHONEGAP_GET_APPS_FAILURE":
      state = {
        ...state,
        loading: false,
        error: true
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default phonegapReducer;
