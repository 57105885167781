const configurationsReducer = (state = {
  loading: false,
  error: false,
  createAppResult: null,
  createAppError: null,
  getAppsError: null,
  getAppsResult: null,
  getAppByIdError: null,
  getAppByIdResult: null
}, action) => {
  switch (action.type) {
    case "MBG_CONFIGURATIONS_CREATE_APP":
      state = {
        ...state,
        loading: true,
        error: false,
        createAppError: null,
        createAppResult: null
      };
      break;
    case "MBG_CONFIGURATIONS_CREATE_APP_SUCCESS":
      state = {
        ...state,
        loading: false,
        createAppResult: action.payload
      };
      break;
    case "MBG_CONFIGURATIONS_CREATE_APP_FAILURE":
      state = {
        ...state,
        loading: false,
        error: true,
        createAppError: action.payload,
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APPS":
      state = {
        ...state,
        loading: true,
        error: false,
        getAppsError: null,
        getAppsResult: null
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APPS_SUCCESS":
      state = {
        ...state,
        loading: false,
        getAppsResult: action.payload
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APPS_FAILURE":
      state = {
        ...state,
        loading: false,
        error: true,
        getAppsError: action.payload,
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APP_BY_ID":
      state = {
        ...state,
        loading: true,
        error: false,
        getAppByIdError: null,
        getAppByIdResult: null
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APP_BY_ID_SUCCESS":
      state = {
        ...state,
        loading: false,
        getAppByIdResult: action.payload
      };
      break;
    case "MBG_CONFIGURATIONS_GET_APP_BY_ID_FAILURE":
      state = {
        ...state,
        loading: false,
        error: true,
        getAppByIdError: action.payload,
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default configurationsReducer;
