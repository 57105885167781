import mobiagApi from "../api/mobiag-api";

export const getCustomerDetails = (dispatch) => {
  dispatch({
    type: "MBG_CUSTOMER_GET_DETAILS"
  });
  mobiagApi.customers.getDetails(
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CUSTOMER_GET_DETAILS_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CUSTOMER_GET_DETAILS_FAILURE",
          payload: error
        })
      }
    }
  );
}
