const authReducer = (state = {
  loggedUserEmail: localStorage.getItem('loggedUserEmail'),
  loggedUserEnvironment: localStorage.getItem('loggedUserEnvironment'),
  loading: false,
  loggedIn: false,
  failedLogin: false
}, action) => {
  switch (action.type) {
    case "IS_AUTHENTICATED":
      state = {
        ...state,
        loading: false,
        loggedIn: true
      };
      break;
    case "AUTHENTICATE":
      state = {
        ...state,
        loading: true
      };
      break;
    case "AUTHENTICATE_SUCCESS":
      localStorage.setItem('loggedUserEmail', action.payload.username);
      localStorage.setItem('loggedUserEnvironment', action.payload.environment);
      state = {
        ...state,
        loading: false,
        loggedIn: true,
        loggedUserEmail: action.payload.username,
        loggedUserEnvironment: action.payload.environment
      };
      break;
    case "AUTHENTICATE_FAILURE":
      state = {
        ...state,
        loggedIn: false,
        loading: false,
        failedLogin: true
      };
      break;
    case "LOGOUT":
      state = {
        ...state,
        loggedIn: false,
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default authReducer;
