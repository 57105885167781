import React from "react";
import { TabContent, TabPane, Nav, ButtonGroup } from "reactstrap";

import TabLink from '../../../_general/tabs/TabLink';
import I18nTab from './I18nTab';
import CsvExporter from "./CsvExporter";
import CsvImporter from "./CsvImporter";


class I18nZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      newI18n: props.changes || {}
    };
    this.toggle = this.toggle.bind(this);
    this.newData = this.newData.bind(this);
    this.importedCsv = this.importedCsv.bind(this);
  }

  toggle(tab) {
    tab = tab.toString();
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  newData(data) {
    //console.log('newData: %o', data);
    let newI18n = { ...this.state.newI18n, ...data };
    this.setState(
      {
        newI18n: newI18n
      }
    );
    this.props.onChange(newI18n);
  }

  importedCsv(result) {
    console.log('result: %o', result);
    this.newData(result);
  }

  renderTabsLinks() {
    if (this.props.i18n && this.props.i18n.tabs && this.props.data) {
      return this.props.i18n.tabs.map((element, index) => {
        return (<TabLink active={this.state.activeTab === (index + 1).toString()}
          key={element.designation}
          title={element.designation}
          onSelected={() => { this.toggle(index + 1); }}
          tooltip={element.description} ></TabLink>)
      });
    }
  }

  renderConfigsTab(element) {
    //console.log('renderConfigsTab this.state.newI18n: %o', this.state.newI18n);
    return (<I18nTab fields={this.props.i18n.fields[element.designation]}
      changesToUpdate={this.props.changesToUpdate}
      selectedVersion={this.props.selectedVersion}
      onNewData={this.newData} newData={this.state.newI18n}
      data={this.props.data.configuration}></I18nTab>)
  }

  // renderCustomerTab() {
  //   return (<ConfigsCustomerTab></ConfigsCustomerTab>);
  // }

  renderTabsContent() {
    if (this.props.i18n && this.props.i18n.tabs && this.props.data) {
      return this.props.i18n.tabs.map((element, index) => {
        return (<TabPane key={'pane_' + element.designation} tabId={(index + 1).toString()}>
          {this.renderConfigsTab(element)}
        </TabPane>);
      });
    }
  }

  render() {
    //console.log(":::::::::::::::", this.props.selectedVersion);
    //console.log("CONFIGS ZONE ---- configs: %o, data: %o", configs, this.props.data);
    return (
      <div>
        <ButtonGroup className="float-right">
          <CsvExporter fields={this.props.i18n} data={this.props.data}/>
          <CsvImporter onChange={this.importedCsv} data={this.props.data}/>
        </ButtonGroup>
        <Nav tabs>
          {this.renderTabsLinks()}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this.renderTabsContent()}
        </TabContent>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default I18nZone;