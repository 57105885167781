import mobiagApi from "../api/mobiag-api";

export const createApp = (appName, configuration, version, dispatch) => {
  dispatch({
    type: "MBG_CONFIGURATIONS_CREATE_APP"
  });
  mobiagApi.configurations.createApp(
    appName,
    configuration,
    version,
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CONFIGURATIONS_CREATE_APP_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CONFIGURATIONS_CREATE_APP_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getApps = (dispatch) => {
  dispatch({
    type: "MBG_CONFIGURATIONS_GET_APPS"
  });
  mobiagApi.configurations.getApps(
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CONFIGURATIONS_GET_APPS_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CONFIGURATIONS_GET_APPS_FAILURE",
          payload: error
        })
      }
    }
  );
}

export const getAppById = (appId, dispatch) => {
  dispatch({
    type: "MBG_CONFIGURATIONS_GET_APP_BY_ID"
  });
  mobiagApi.configurations.getAppById(
    appId,
    (response, error) => {
      if (!error) {
        dispatch({
          type: "MBG_CONFIGURATIONS_GET_APP_BY_ID_SUCCESS",
          payload: response.data.result
        })
      }
      else {
        dispatch({
          type: "MBG_CONFIGURATIONS_GET_APP_BY_ID_FAILURE",
          payload: error
        })
      }
    }
  );
}
