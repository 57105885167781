import React, { Component } from "react";
import { connect } from "react-redux";
import { connectToZero, closeSocket } from "../../Store/Actions/zeroActions";
import { getLocations } from "../../Store/Actions/locationActions";
import { Button } from "reactstrap";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const center = {
  "lat": 38.74181, "lng": -9.15
}

const markerRed = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABRSURBVChTY/j//z/DfwYWKSDuA2IgD4xBbCmQHEzyMVQCGYPEpEAKkHWi41WMIAYDHsAEpXECkIJ+CBMrWE2EI4FMEAOIV0ElQBjEBnrzPwMA7uJNlDLJCgsAAAAASUVORK5CYII=";
const markerBlack = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABQSURBVChTY/j//z8DEEgBcR8QgzggDGJLQeXAko+BGCYJwyAxkByKTnS8ihHKwAmYoDROwAzE/EBsCeZhgtUgAr8jkby5CohhkiA20Jv/GQCfEyff0yOlkQAAAABJRU5ErkJggg==";
const markerGreen = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABvSURBVChTY/z//z8D4zohKQYGhhIgLgRiEOgH4p7/Qe+eMTKsFQRJngRiGZAMEngCxOZMQAKkE10SBEBiE0Am/IfwsQOQCXgBSAHIQbjAapCCHiAGOQgdgMQKmEBeATLMgXg1SBQKQGzz/0HvngEAgFEa6D1kKe8AAAAASUVORK5CYII=";
const markerBlue = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABsSURBVChTfY/RCYAwDAWjY4h7VNGhFBxD0D1cQyjuIV0jvkM/RNSDK2mShjRzd6tbK8xskL2ESY5xtZRVjVPcZEnlxi5DroOXzyKQm5ng5/0dJvxCAwt9sdAwShZ6Qq7L+YqCIBeyF8QhrpYOi+kZ6N4PkzYAAAAASUVORK5CYII=";
const markerPurple = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABuSURBVChTY/z//z9DVzyDFAMDQwkQFwIxCPQDcU/ZQoZnjJ1x/0GSJ4FYBiSDBJ4AsTkTkADpRJcEAZDYBJACmLHYQChIAV4AUgByEC6wGqSgB4hBDkIHILECJpBXgAxzIF4NEoUCENu8bCHDMwDXdhfKgTJW3QAAAABJRU5ErkJggg==";

class MvpContainer extends Component {
  constructor() {
    super();
    this.state = {
      counter: 0,
      incCounter: 0,
      counterPerSec: 0,
      intervalId: 0,
      intervalId2: 0,
      markers: []
    };
  }

  componentDidMount() {
    this.getInitialElements();
  }

  startWorker() {
    let interval = setInterval(() => {
      let m = this.state.markers;
      let newElement = this.getRandomElement();
      let index = m.findIndex((el) => el.id === newElement.id);
      m[index] = this.elementToMarker(newElement);
      this.setState({
        counter: this.state.counter + 1,
        markers: m
      });
    }, 1);
    this.setState({ intervalId: interval });
    let interval2 = setInterval(() => {
      this.setState({
        incCounter: this.state.counter,
        counterPerSec: this.state.counter - this.state.incCounter
      });
    }, 1000);
    this.setState({ intervalId2: interval2 });
  }

  stopWorker() {
    clearInterval(this.state.intervalId);
    this.setState({ intervalId: 0 });
    clearInterval(this.state.intervalId2);
    this.setState({ intervalId2: 0 });
  }

  elementToMarker(element) {
    let marker = markerRed;
    if (element.net <= 0.15) {
      marker = markerPurple
    }
    else if (element.fuel <= 0.30) {
      marker = markerBlue
    }
    else if (element.offline) {
      marker = markerGreen
    }
    else if (element.active) {
      marker = markerBlack
    }
    return {
      id: element.id,
      position: {
        lat: element.latitude,
        lng: element.longitude
      },
      icon: marker
    }
  }

  getInitialElements() {
    let elements = this.state.markers;
    for (let i = 0; i < 5000; i++) {
      elements.push(this.elementToMarker(this.getRandomElement(i + 1)));
    }
    this.setState({
      'markers': elements
    })
  }

  getRandomElement(id) {
    return {
      "id": id ? id : parseInt(this.getRandomInRange(1, 5000, 0)),
      "active": this.getRandomInRange(1, 10, 1) > 2 ? true : false,
      "offline": this.getRandomInRange(1, 10, 1) > 9 ? true : false,
      "fuel": this.getRandomInRange(0, 1, 2),
      "net": this.getRandomInRange(0, 1, 2),
      "latitude": this.getRandomInRange(38.7, 38.8, 5),
      "longitude": this.getRandomInRange(-9.1, -9.25, 5)
    }
  }

  getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
    // .toFixed() returns string, so ' * 1' is a trick to convert to number
  }

  //38.74181,-9.19912,13.5z
  renderMarkers() {
    // let pos = ;
    // for (let i = 0; i < 10; i++) {
    //   let position = {
    //     lat: this.getRandomInRange(38.7, 38.9, 5),
    //     lng: this.getRandomInRange(-9.1, -9.4, 5)
    //   }
    //   pos.push(position);
    // }
    return this.state.markers.map((element) => {
      return (
        <Marker
          key={element.id}
          position={element.position}
          icon={element.icon}
        />
      )
    });
  }

  render() {
    return <div>
      <div className="mvp-sidebar">
        <Button color='primary' size="sm" onClick={this.startWorker.bind(this)}>Start</Button><br /><br />
        <Button color='primary' size="sm" onClick={this.stopWorker.bind(this)}>Stop</Button><br /><br />
        <h3>{this.state.counter}</h3>
        <h3>{this.state.counterPerSec} p/sec</h3>
        <hr />
        <b>Legend</b><br />
        <img alt="de-active" src={markerRed} /><span> de-active</span><br />
        <img alt="active" src={markerBlack} /><span> active</span><br />
        <img alt="offline" src={markerGreen} /><span> offline</span><br />
        <img alt="fuel" src={markerBlue} /><span> fuel &lt; 30%</span><br />
        <img alt="net" src={markerPurple} /><span> net &lt; 15%</span><br />
      </div>
      <div className="mvp-map">
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyCczoevBdPlepkEvyeBdiS0n2HbC9FhIKg"
        >
          <GoogleMap
            id='example-map'
            zoom={13}
            center={center}
          >
            {this.renderMarkers()}
          </GoogleMap>
        </LoadScript>
      </div>
    </div >;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.zeroReducer.loading,
    websocketConnected: state.zeroReducer.connected,
    zeroConnectionError: state.zeroReducer.zeroConnectionError,
    output: state.locationsReducer.locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectToZero: (token) => {
      connectToZero(token, dispatch);
    },
    getLocations: () => {
      getLocations(dispatch);
    },
    closeSocket: () => {
      closeSocket(dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MvpContainer);
