import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Card, CardHeader, CardFooter } from 'reactstrap';
import JsonToHtml from '../../../JsonToHtml';

class UpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    console.log('SUBMIT!!');
    this.toggle();
    this.props.onConfirm();
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  open() {
    this.setState({
      modal: true
    });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} size="xl" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Update Remote Config</ModalHeader>
          <ModalBody>
            <div className="app-info-update">
              <img className="logo" src={this.props.app.icon} alt={this.props.app.app_name}></img>
              <div className="details">
                <span className="name">{this.props.app.app_name}</span>
                <span className="version">{this.props.version}</span>
              </div>
            </div>
            <br />
            <hr/>
            <p>Please confirm the update you are doing!</p>
            <Button color="primary" onClick={this.submit}>Update</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            <hr/>
            <br />
            <Row>
              <Col md={{ size: 6 }}>
                <Card className="card-json" color="primary">
                  <CardHeader>New Configs</CardHeader>
                  <CardFooter>
                    <JsonToHtml object={this.props.configChanges}></JsonToHtml>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={{ size: 6 }}>
                <Card className="card-json" color="primary">
                  <CardHeader>New I18n</CardHeader>
                  <CardFooter>
                    <JsonToHtml object={this.props.i18nChanges}></JsonToHtml>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}>Update</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UpdateModal;