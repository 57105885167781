import React from "react";
import { connect } from "react-redux";
import { authenticate, isAuthenticated } from "../Actions/authActions";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";

class LoginContainer extends React.Component {
  componentDidMount() {
    if (this.props.isAuthenticated()) {
      window.location('/cars');
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      selectedEnv: 'PROD'
    };
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
    //this.props[e.target.name] = e.target.value;
  }

  handleOptionChange(e) {
    this.setState({
      selectedEnv: e.target.value
    });
  }

  render() {
    let errorMessagetHtml = "";
    if (this.props.failedLogin) {
      errorMessagetHtml = (
        <div><br /><Alert color="danger">Invalid user or password</Alert></div>
      );
    }

    return (
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle>Login</CardTitle>
              <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    type="email"
                    name="username"
                    onChange={this.handleChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    onChange={this.handleChange.bind(this)}
                  />
                </FormGroup>
              </Form>
              <br />
              <Button type="primary" disabled={this.props.loading} onClick={() => this.props.authenticate(this.state.username, this.state.password, this.state.selectedEnv)}>Login</Button>
              {errorMessagetHtml}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.loading,
    loggedIn: state.authReducer.loggedIn,
    failedLogin: state.authReducer.failedLogin
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    isAuthenticated: () => {
      isAuthenticated(dispatch)
    },
    authenticate: (user, password, env) => {
      console.log('user: %o, password: %o', user, password);
      authenticate(user, password, env, dispatch)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
