const processError = (error) => {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message.toString();
      }
      else {
        return error.response.data.toString();
      }
    }
    else {
      return error.response.toString();
    }
  }
  return error.toString();
}

const remoteConfigsReducer = (state = {
  loading: false,
  getConfigsResult: null,
  getConfigsError: null,
  createConfigResult: null,
  createConfigError: null
}, action) => {
  switch (action.type) {
    case "RC_GETCONFIGS":
      state = {
        ...state,
        loading: true,
        getConfigsError: null,
        getConfigsResult: null
      };
      break;
    case "RC_GETCONFIGS_SUCCESS":
      state = {
        ...state,
        loading: false,
        getConfigsResult: action.payload
      };
      break;
    case "RC_GETCONFIGS_FAILURE":
      //console.log(action.payload.response.data.message);
      state = {
        ...state,
        loading: false,
        getConfigsError: processError(action.payload)
      };
      break;
    case "RC_CREATECONFIG":
      state = {
        ...state,
        loading: true,
        createConfigError: null,
        createConfigResult: null
      };
      break;
    case "RC_CREATECONFIG_SUCCESS":
      state = {
        ...state,
        loading: false,
        createConfigResult: action.payload
      };
      break;
    case "RC_CREATECONFIG_FAILURE":
      //console.log(action.payload.response.data.message);
      state = {
        ...state,
        loading: false,
        createConfigError: processError(action.payload)
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default remoteConfigsReducer;
