import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const createUUID = () => {
	let dt = new Date().getTime();
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
		let random = (dt + Math.random()*16)%16 | 0;
		dt = Math.floor(dt/16);
		// eslint-disable-next-line
		return (char==='x' ? random : random&0x3|0x8).toString(16);
	});
	return uuid;
};

class ZeroWebSocket {
  // options : { url, path, debug }
  constructor(options) {
    let defaultOptions = {
      debug: false,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      topic: '/app/hello'
    }
    this.options = { ...defaultOptions, ...options }

    this.callbacks = {};
    this.activeRequests = {};

    this.client = new Client({
      debug: (str) => {
        if (this.options.debug) { 
          console.log("[DEBUG] " + str)
        }
      },
      reconnectDelay: this.options.reconnectDelay,
      heartbeatIncoming: this.options.heartbeatIncoming,
      heartbeatOutgoing: this.options.heartbeatOutgoing,
    });

    let socket;

    this.client.webSocketFactory = () => {
      socket = new SockJS(this.options.url + this.options.path);
      return socket;
    };

    this.client.onConnect = (msg) => {
      this.triggerCallback('connect', msg);

      const sessionId = socket._transport.url.match(
        /ws[s]{0,1}:\/\/.+\/.+\/(.+)\/websocket/
      );

      if (!sessionId || sessionId.length < 2) {
        //console.log(`Invalid session id: ${sessionId}`);
        //console.log(`Socket url: ${socket._transport.url}`);
        // TODO check why Socket fails and deal with it.
        // This is a temporary fix to a 'random' behavior.
        this.triggerCallback('connectError', 'INVALID_SESSION_ID');
        return;
      }

      //console.log(`Session id: ${sessionId[1]}`);

      // TODO the subscriptions and their onMessage callbacks should be set dynamically
      this.client.subscribe(
        '/secured/user/queue/specific-user-user' + sessionId[1],
        (message) => {
          let body;
    
          try {
            body = JSON.parse(message.body);
          } catch {
            console.log('Error parsing body JSON: ', message.body);
            return;
          }
    
          try {
            const { wsid, reporting, error } = body;
            // TODO check response for success, error and report 
            //console.log('RESULT: %o', processMessage);

            if (error) {
              this.triggerCallback('messageError', body);
              if (this.activeRequests[wsid]) {
                this.activeRequests[wsid](body);
                delete this.activeRequests[wsid];
              }
            }
            else if (reporting) {
              this.triggerCallback('messageReport', body);
            }
            else {
              this.triggerCallback('messageSuccess', body);
              if (this.activeRequests[wsid]) {
                this.activeRequests[wsid](body);
                delete this.activeRequests[wsid];
              }
            }
    
          } catch (error) {
            console.log('WEBSOCKET ERROR : : ', error);
          }
        }
      );
    }
  }

  triggerCallback = (event, obj) => {
    this.callbacks[event] && this.callbacks[event](obj);
  }
  
  onConnect = (callback) => {
    this.callbacks['connect'] = callback;
  };

  onConnectError = (callback) => {
    this.callbacks['connectError'] = callback;
  };

  onMessageSuccess = (callback) => {
    this.callbacks['messageSuccess'] = callback;
  };

  onMessageError = (callback) => {
    this.callbacks['messageError'] = callback;
  };

  onMessageReport = (callback) => {
    this.callbacks['messageReport'] = callback;
  };

  /* WEBSOCKET AUTHENTICATION */
  loginToWS = async (token) => {
    //console.log('loginToWS token: %o', token);
    const formData = new URLSearchParams();
    formData.append('username', 'user');
    formData.append('password', token);
    let response = await fetch(
      this.options.url + '/authenticate',
      {
        credentials: 'include',
        method: 'post',
        body: formData,
      }
    );
    return response.ok;
  };

  /* WEBSOCKET CONNECTION */
  connect = async (token) => {
    if (this.client.active) {
      this.client.deactivate();
    }
    this.loginToWS(token);
    //let wsLogin = await this.loginToWS(token);
    // if (wsLogin) {
    //   this.client.activate();
    // }
    // else {
    //   this.triggerCallback('connectError', 'AUTHENTICATION_FAILURE');
    // }
  }

  close = async (token) => {
    console.log('close WS');
    this.client.forceDisconnect();
    this.client.deactivate();
  }

  /* WEBSOCKET PUBLISH */
  publish = (type, subType, message) => {
    let body = { wsid: createUUID(), type, subType, message };
    this.client.publish({
			destination: this.options.topic,
			headers: {},
			body: JSON.stringify({ "name": JSON.stringify(body) }),
		});
  }

  publishWithCallback = (type, subType, message, callback) => {
    let body = { wsid: createUUID(), type, subType, message };
    this.activeRequests[body.wsid] = callback;
    this.client.publish({
			destination: this.options.topic,
			headers: {},
			body: JSON.stringify({ "name": JSON.stringify(body) }),
    });
  }
}

const zero = new ZeroWebSocket({
  url: 'http://54.77.61.58:8678',
  path: '/gs-guide-websocket',
  debug: false }
);

export default zero;