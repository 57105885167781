import React from "react";
import {
  Card, CardHeader, CardBody, Collapse, Row, Col
} from "reactstrap";
import JsonToHtml from "./JsonToHtml";

class MethodBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bodyCollapse: !this.props.open };
  }

  toggle() {
    this.setState({ bodyCollapse: !this.state.bodyCollapse });
  }

  render() {
    return (
      <Card className="method-box">
        <CardHeader onClick={this.toggle.bind(this)}>{this.props.title}</CardHeader>
        <Collapse isOpen={!this.state.bodyCollapse}>
          <CardBody>
            <Row>
              <Col sm="4">
                {this.props.children}
              </Col>
              <Col sm="8">
                <div className={(this.props.error ? 'error' : '')}>
                  <JsonToHtml object={this.props.error ? this.props.error : this.props.result} />
                </div>
              </Col>
            </Row>
            
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default MethodBox;