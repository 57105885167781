import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Alert,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Badge,
  Container
} from "reactstrap";

import { utils } from "../../utils";

const file126 = require("../../data/i18n/1.26.json");
const file127 = require("../../data/i18n/1.27.json");
const file128 = require("../../data/i18n/1.28.json");
const file129 = require("../../data/i18n/1.29.json");
const file130 = require("../../data/i18n/1.30.json");
const file131 = require("../../data/i18n/1.31.json");
const file132 = require("../../data/i18n/1.32.json");
const file135 = require("../../data/i18n/1.35.json");


class I18nValidatorContainer extends Component {
  constructor() {
    super();
    this.state = {
      importDone: false,
      jsonValid: false,
      errorMessage: "",
      missingkeys: [],
      invalidkeys: [],
      jsonVersion: file126,
      uploadedFile: undefined
    };
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);

    this.setState({
      uploadedFile: file
    });
    this.checkFile(file, this.state.jsonVersion);
  }

  checkFile(file, checkFile) {
    console.log("checkFile - file: %o", file);
    if (file) {
      //let checkFile = this.state.jsonVersion;
      utils.getFileAsJson(file, (result, error) => {
        if (!error) {
          console.log("checkFile: %o", checkFile);
          //Validate Missing Keys
          let missingkeys = [];
          Object.keys(checkFile).forEach(function (key) {
            if (typeof result[key] === "undefined") {
              missingkeys.push(key);
            }
          });

          //Validate Invalid Keys
          let invalidkeys = [];
          Object.keys(result).forEach(function (key) {
            if (typeof checkFile[key] === "undefined") {
              invalidkeys.push(key);
            }
          });

          this.setState({
            importDone: true,
            jsonValid: true,
            missingkeys: missingkeys,
            invalidkeys: invalidkeys
          });
        } else {
          console.log("error: %o", error);
          let errorPos = parseInt(error.toString().substr(error.toString().lastIndexOf(' ') + 1));
          let lineError = utils.lineCounting(result, errorPos);
          let errorMsg = error.toString().substring(13, error.toString().indexOf(' at pos') > 0 ? error.toString().indexOf(' at pos') : error.toString().length);
          let errorsnipet = result.substring(result.lastIndexOf('\n', errorPos), result.indexOf('\n', errorPos));
          this.setState({
            importDone: true,
            jsonValid: false,
            errorMessage: (<div>{errorMsg}<br />on line {lineError}<br /><pre>{errorsnipet}</pre></div>)
          });
        }
      });
    }
  }

  onChangeVersion(event) {
    console.log("Change version1: %o", event.target.value);
    let jsonVersion = file126;
    switch (event.target.value) {
      case "127":
        jsonVersion = file127;
        break;
      case "128":
        jsonVersion = file128;
        break;
      case "129":
        jsonVersion = file129;
        break;
      case "130":
        jsonVersion = file130;
        break;
      case "131":
        jsonVersion = file131;
        break;
      case "132":
        jsonVersion = file132;
        break;
      case "135":
        jsonVersion = file135;
        break;
      default:
        break;
    }
    this.setState({
      jsonVersion: jsonVersion
    });
    this.checkFile(this.state.uploadedFile, jsonVersion);
  }

  download() {
    utils.download("config.html", "teste");
  }

  render() {
    let resultHtml = "";
    let missingkeysHtml = "";
    let invalidkeysHtml = "";
    if (this.state.importDone) {
      if (this.state.jsonValid) {
        resultHtml = <Alert color="success">JSON Valid</Alert>;
        if (this.state.missingkeys.length > 0) {
          missingkeysHtml = (
            <div>
              <Alert color="danger">
                Missing Keys
                {"   "}
                <Badge color="secondary">{this.state.missingkeys.length}</Badge>
              </Alert>
              {this.state.missingkeys.map(item => (
                <p className="text-danger" key={item}>
                  {item}
                </p>
              ))}
            </div>
          );
        }
        if (this.state.invalidkeys.length > 0) {
          invalidkeysHtml = (
            <div>
              <Alert color="danger">
                Invalid Keys
                {"   "}
                <Badge color="secondary">{this.state.invalidkeys.length}</Badge>
              </Alert>
              {this.state.invalidkeys.map(item => (
                <p className="text-danger" key={item}>
                  {item}
                </p>
              ))}
            </div>
          );
        }
      } else {
        if (this.state.errorMessage) {
          resultHtml = (
            <div>
              <Alert color="danger">Invalid JSON</Alert>
              <div className="text-danger">{this.state.errorMessage}</div>
            </div>
          );
        } else {
          resultHtml = <Alert color="danger">Invalid JSON</Alert>;
        }
      }
    }
    return (
      <div>
        <br />
        <h5>i18n Validator</h5>
        <hr />
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <br />
              <h3>Phoneapp i18n Validator</h3>
              <br />
              <Button color="primary" onClick={() => this.upload.click()}>
                Upload
              </Button>
              <br />
              <input
                id="myInput"
                type="file"
                ref={ref => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.onChangeFile.bind(this)}
              />
              <br />
              <FormGroup>
                <Label for="exampleSelect">Version</Label>
                <Input
                  type="select"
                  ref="version_select"
                  onChange={this.onChangeVersion.bind(this)}
                >
                  <option value="130">1.30</option>
                  <option value="131">1.31</option>
                  <option value="132">1.32</option>
                  <option value="135">1.35</option>
                </Input>
              </FormGroup>
              {resultHtml}
              {missingkeysHtml}
              {invalidkeysHtml}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(I18nValidatorContainer);
