import sha1 from 'crypto-js/sha1';
import mbgApiBehavior from "../api/mobiag-api-behavior";
const config = require('../data/config');

let _authToken = localStorage.getItem('authToken');
let _authTokenExpireDate = localStorage.getItem('authTokenExpireDate');
let _username = localStorage.getItem('username');
let _password = localStorage.getItem('password');
let _apiUrl = localStorage.getItem('apiUrl');
let _apiAuthUrl = localStorage.getItem('apiAuthUrl');


function _updateAuthToken(callback) {
  console.log("----- _updateAuthToken");
  if (!_username || !_password) {
    callback(null, "INVALID_SESSION");
    return;
  }
  //restrinction
  if (_username !== "msl.debug@mailinator.com") {
    callback(null, "NOT_AUTHORIZED");
  }
  mbgApiBehavior.auth(
    _apiAuthUrl,
    _username,
    _password,
    (token, error) => {
      if (error) {
        callback(null, "INVALID_SESSION");
      }
      else {
        _authToken = token;
        _authTokenExpireDate = new Date();
        _authTokenExpireDate.setHours(_authTokenExpireDate.getHours() + 1);
        localStorage.setItem('authToken', _authToken);
        localStorage.setItem('authTokenExpireDate', _authTokenExpireDate);
        localStorage.setItem('username', _username);
        localStorage.setItem('password', _password);
        localStorage.setItem('apiUrl', _apiUrl);
        localStorage.setItem('apiAuthUrl', _apiAuthUrl);
        callback(token);
      }
    });
}

function _authValid() {
  let result = _authToken && _authToken.length > 0;
  if (result && _authTokenExpireDate && _authTokenExpireDate > new Date()) {
    console.log('expired!!!');
    return false;
  }
  return result;
}

function _get(method, params, callback) {
  if (_authValid()) {
    mbgApiBehavior.get(_authToken, _apiUrl, method, params, callback);
  }
  else {
    _updateAuthToken(
      () => {
        mbgApiBehavior.get(_authToken, _apiUrl, method, params, callback);
      }
    );
  }
}


function _post(method, params, callback) {
  console.log("method: %o, params: %o", method, params);
  if (_authValid()) {
    mbgApiBehavior.post(_authToken, _apiUrl, method, params, callback);
  }
  else {
    _updateAuthToken(
      () => {
        mbgApiBehavior.post(_authToken, _apiUrl, method, params, callback);
      }
    );
  }
}


function _formatDate(date) {
  //dd-MM-yyyy HH:mm:ss
  if (date) {
    var day = date.getDate();
    day = day < 10 ? "0" + day.toString() : day.toString();
    var month = date.getMonth() + 1;
    month = month < 10 ? "0" + month.toString() : month.toString();
    var hours = date.getHours();
    hours = hours < 10 ? "0" + hours.toString() : hours.toString();
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    var seconds = date.getSeconds();
    seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
    return day + '-' + month + '-' + date.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds;
    //return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds;
  }
  return "";
}

const mobiagApi = {
  authenticate: function (username, password, env, callback) {
    if (env === 'PROD') {
      // _apiAuthUrl = window.location && window.location.hostname !== 'localhost' ? config.mbgApiProdAuthUrl : "https://cors-anywhere.herokuapp.com/" + config.mbgApiProdAuthUrl;
      // _apiUrl = window.location && window.location.hostname !== 'localhost' ? config.mbgApiProdUrl : "https://cors-anywhere.herokuapp.com/" + config.mbgApiProdUrl;
      _apiAuthUrl = config.mbgApiProdAuthUrl;
      _apiUrl = config.mbgApiProdUrl;
    }
    else {
      _apiAuthUrl = config.mbgApiIntAuthUrl;
      _apiUrl = config.mbgApiIntUrl;
    }
    _username = username;
    _password = sha1(password);
    _updateAuthToken(callback);
  },
  isAuthenticated: function () {
    return _authValid();
  },
  logout: function () {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenExpireDate');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('apiUrl');
    localStorage.removeItem('apiAuthUrl');
    _authToken = null;
    _authTokenExpireDate = null;
    _username = null;
    _password = null;
  },
  formatDate(date) {
    //dd-MM-yyyy HH:mm:ss
    if (date) {
      var day = date.getDate();
      day = day < 10 ? "0" + day.toString() : day.toString();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month.toString() : month.toString();
      var hours = date.getHours();
      hours = hours < 10 ? "0" + hours.toString() : hours.toString();
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
      var seconds = date.getSeconds();
      seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
      return day + '-' + month + '-' + date.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds;
    }
    return "";
  },
  formatDate2(date) {
    //dd-MM-yyyy HH:mm:ss
    if (date) {
      var day = date.getDate();
      day = day < 10 ? "0" + day.toString() : day.toString();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month.toString() : month.toString();
      var hours = date.getHours();
      hours = hours < 10 ? "0" + hours.toString() : hours.toString();
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
      var seconds = date.getSeconds();
      seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
      return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + '.000Z';
    }
    return "";
  },
  /*
  *  ##### ADDONS #####
  */
  addons: {
    // DELETE /addons - Delete a add-on

    // GET /addons - Get all add-ons.

    // POST /addons - Create add-on

    // PUT /addons - Update a add-on

    // DELETE /addons/booking - Delete a booking add-on

    // POST /addons/booking - Create a add-on on booking

    // GET /addons/{type}/{reservationCode} - Get all add-ons from a specific type
  },
  /*
  *  ##### CARCLUB #####
  */
  carclub: {
    // GET/carclub/configurations - Get configurations of a specific carclub.

    // GET /carclub/location - Get location in use for carclub.

    // GET /carclub/zones - Get zones list by location.
  },
  /*
  *  ##### CARS #####
  */
  cars: {
    //GET /cars - Get cars from a fleet
    getCars: function (location, callback) {
      let params = {
        location: location
      }
      _get('cars', params, callback);
    },

    //GET /cars/all - Get all cars that user can seen.
    getAll: function (callback) {
      _get('cars/all', null, callback);
    },

    //GET /cars/brand/ - Gets brands by id, or all of them if null id..
    getBrands: function (id, callback) {
      let params = null;
      if (id) {
        params = {
          id: id
        };
      }
      _get('cars/brand/', params, callback);
    },

    //GET /cars/class/ - Gets classes by id, or all if null id
    getClasses: function (id, callback) {
      let params = null;
      if (id) {
        params = {
          id: id
        };
      }
      _get('cars/class/', params, callback);
    },

    //GET /cars/communications - Get communications

    //DELETE /cars/groups - Delete a car group.

    //GET /cars/groups - Get a car groups by code, or all if null code..
    getGroups: function (groupCode, callback) {
      let params = null;
      if (groupCode) {
        params = {
          groupCode: groupCode
        };
      }
      _get('cars/groups', params, callback);
    },

    //POST /cars/groups - Create a car group.

    //PUT /cars/groups - Update a car group.

    //POST /cars/groups/{group_code}/add-cars - Adds group_id to car.

    //GET /cars/model/ - Gets models by id, or all if id is null.

    //GET /cars/{car_id}/availability - Get periods of car availability.

    //POST /cars/{car_id}/command - Send command to car.

    //POST /cars/{car_id}/create-damage - Create one car damage.

    //GET /cars/{car_id}/damage - Get list off car damage.

    //GET /cars/{car_id}/details - Get car details information.

    //POST /cars/{car_id}/device-token - Get device token.

    //GET /cars/{car_id}/image - Get car image.

    //GET /cars/{car_id}/obs - Get car obs information.

    //POST /cars/{car_id}/virtual-key - Get bluetooth key.
    getCarVirtualKey: function (carId, phoneUuid, endDate, reservationCode, callback) {
      let params = {
        "reservation_code": reservationCode,
        "phone_uuid": phoneUuid,
        "end_date": _formatDate(endDate)
      }
      _post('cars/' + carId + '/virtual-key', params, callback);
    }

    //GET /cars/{car_id}/zones - Get car´s zones information.
  },
  /*
  *  ##### CONFIGURATIONS #####
  */
  configurations: {
    // POST /configurations/add-app - Inserted app for the car club.
    createApp: function (appName, configuration, version, callback) {
      let data = {
        "app_name": appName,
        "configuration": configuration,
        "version": version
      }
      _post('configurations/add-app', data, callback);
    },
    // GET /configurations/app - Get list of all apps for the car club.
    getApps: function (callback) {
      _get('configurations/app', null, callback);
    },
    // GET /configurations/app/{app_id} - Get getails off one specified app
    getAppById: function (appId, callback) {
      _get('configurations/app/' + appId, null, callback);
    }
  },
  /*
  *  ##### CUSTOMERS #####
  */
  customers: {
    // GET /customers/carclub - Get information if the specified customer belongs to carclub.

    // GET /customers/details - Get the customer details off the specified customer.
    getDetails: function (callback) {
      _get('customers/details', null, callback);
    }
    // GET /customers/validate - Get information of the specified customer.

    // GET /customers/{customer_code}/details - Get the customer details off the specified customer.
  },
  
  /*
  *  ##### RESERVATIONS #####
  */
  reservations: {
    // GET /reservations - Get all reservations.
    getReservations: function(callback) {
      _get('reservations', null, callback);
    },

    // POST /reservations - Create a reservation.
    // body:
    // {
    //   "addons_code": [
    //     "string"
    //   ],
    //   "brand_code": 0,
    //   "canceled": true,
    //   "canceled_user": "string",
    //   "carAvailable": true,
    //   "carCheck": true,
    //   "carToken": "string",
    //   "car_code": "string",
    //   "carclubCode": "string",
    //   "cargroup_code": 0,
    //   "changeDatetime": "2019-01-22T14:11:03.807Z",
    //   "changeUsername": "string",
    //   "class_code": 0,
    //   "closed": true,
    //   "code": "string",
    //   "createDatetime": "2019-01-22T14:11:03.807Z",
    //   "createUsername": "string",
    //   "createdByCallCenter": true,
    //   "customerAuthorized": true,
    //   "customerCheck": true,
    //   "customerCode": "string",
    //   "customer_id": "string",
    //   "customer_pin": 0,
    //   "customer_requirement": "string",
    //   "dateManuallySet": true,
    //   "endDate": "2019-01-22T14:11:03.807Z",
    //   "endLatitude": 0,
    //   "endLogitude": 0,
    //   "endZone": "string",
    //   "end_time": "string",
    //   "expired": true,
    //   "extended": true,
    //   "extendedByCallCenter": true,
    //   "group_id": "string",
    //   "id": "string",
    //   "internalReservation": true,
    //   "invoiced": true,
    //   "lastVersion": "string",
    //   "location": "string",
    //   "model_code": 0,
    //   "price": 0,
    //   "readyToStart": true,
    //   "requestCarBrand": 0,
    //   "requestCarClassCode": "string",
    //   "requestCarGroup": "string",
    //   "requestCarModelCode": 0,
    //   "requestCarPlate": "string",
    //   "requestCustomer": "string",
    //   "requestEndDate": "2019-01-22T14:11:03.807Z",
    //   "requestEndZone": "string",
    //   "requestGroup": "string",
    //   "requestLocation": "string",
    //   "requestPrice": 0,
    //   "requestStartDate": "2019-01-22T14:11:03.807Z",
    //   "requestStartZone": "string",
    //   "requestUser": "string",
    //   "startDate": "2019-01-22T14:11:03.807Z",
    //   "startLatitude": 0,
    //   "startLongitude": 0,
    //   "startZone": "string",
    //   "start_time": "string",
    //   "tripEnded": true,
    //   "tripStarted": true,
    //   "version": "string",
    //   "zoneCheck": true
    // }
    createReservation: function(body, callback) {
      _post('reservations', body, callback);
    }

    // PUT /reservations - Update reservation.

    // POST /reservations/active - Activate reservation.

    // POST /reservations/booking - Creates a booking.

    // GET /reservations/cancel - Cancels the reservation.

    // GET /reservations/next - Get next reservation.

    // GET /reservations/simulator - Get calculate a simulation price for a reservation.

    // GET /reservations/{reservation_code} - Get specific reservation info.
  }
}


export default mobiagApi
