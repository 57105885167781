import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container
} from "reactstrap";
import { getConfigs, addRemoteConfig } from "../../Actions/remoteConfigsActions";

import { utils } from "../../utils";

class RawRemoteConfigsContainer extends Component {
  constructor(props) {
    super(props);
    var configText = '';
    console.log('INIT: %o', props)
    if (props.getConfigsResult) {
      configText = JSON.stringify(props.getConfigsResult);
    }
    this.state = {
      name: '',
      version: '',
      configurationText: configText
    };
  }

  handleAppNameChange(e) {
    let value = e.target.value;
    this.setState({
      name: value
    });
  }

  handleAppVersionChange(e) {
    let value = e.target.value;
    this.setState({
      version: value
    });
  }

  handleConfigsChange(e) {
    let value = e.target.value;
    this.setState({
      configurationText: value
    });
  }

  updateConfigs() {
    try {
      if (this.state.name && this.state.version && this.state.configurationText) {
        var configs = JSON.parse(this.state.configurationText);
        console.log(this.state.name);
        console.log(this.state.version);
        console.log(configs);
        this.props.addRemoteConfig(this.state.name, this.state.version, {
          configs: configs
        });
      }
      else {
        throw "REQUIRED_FIELD";
      }
    }
    catch (error) {
      if (error !== "REQUIRED_FIELD") {
        let errorPos = parseInt(error.toString().substr(error.toString().lastIndexOf(' ') + 1));
        let lineError = utils.lineCounting(this.state.configurationText, errorPos);
        let errorMsg = error.toString().substring(13, error.toString().indexOf(' at pos') > 0 ? error.toString().indexOf(' at pos') : error.toString().length);
        let errorsnipet = this.state.configurationText.substring(this.state.configurationText.lastIndexOf('\n', errorPos), this.state.configurationText.indexOf('\n', errorPos));
        console.error(errorMsg + " on line " + lineError + " - " + errorsnipet);
      }
      else {
        console.error(error);
      }
    }

  }

  getConfigs() {
    this.props.getConfigs(this.state.name, this.state.version);
  }

  render() {
    return (
      <div>
        <br />
        <h5>Raw Remote Configs</h5>
        <hr />
        <Container>
          <FormGroup>
            <Label>App Name</Label>
            <Input type="text" bsSize="sm" value={this.state.name} onChange={this.handleAppNameChange.bind(this)} />
          </FormGroup>
          <FormGroup>
            <Label>App Version</Label>
            <Input type="text" bsSize="sm" value={this.state.version} onChange={this.handleAppVersionChange.bind(this)} />
          </FormGroup>
          <Button color="primary" size="sm" onClick={this.getConfigs.bind(this)}>Get configs</Button>
          <br />
          {(this.props.getConfigsResult && this.props.getConfigsResult.app_configuration_id) ? this.props.getConfigsResult.app_configuration_id : ""}
          <br />
          <FormGroup>
            <Label>Configs</Label>
            <Input type="textarea" rows="10" bsSize="sm" value={this.state.configurationText} onChange={this.handleConfigsChange.bind(this)} />
          </FormGroup>
          <Button color="primary" size="sm" onClick={this.updateConfigs.bind(this)} >Update</Button>
        </Container>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.remoteConfigsReducer.loading,
    getConfigsResult: state.remoteConfigsReducer.getConfigsResult,
    getConfigsError: state.remoteConfigsReducer.getConfigsError,
    createConfigResult: state.remoteConfigsReducer.createConfigResult,
    createConfigError: state.remoteConfigsReducer.createConfigError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfigs: (app_name, app_version) => {
      getConfigs(app_name, app_version, dispatch);
    },
    addRemoteConfig: (app_name, app_version, app_config) => {
      addRemoteConfig(app_name, app_version, app_config, dispatch);
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RawRemoteConfigsContainer);
