import React from "react";
import AppIcon from "./AppIcon";
import { Col, Row } from "reactstrap";

class AppSelector extends React.Component {
  handleChange(key) {
    this.props.onChange(key);
  }

  renderApps(list) {
    let outHtml = "";
    if (list) {
      outHtml = list.map((item) => {
        return (<Col key={item.app_name} md={{ size: 6 }}><AppIcon id={item.app_name} app={item} onClick={this.handleChange.bind(this)}></AppIcon></Col>)
      })
    }
    return outHtml;
  }

  render() {
    let appsHtml = "";
    if (this.props.apps) {
      appsHtml = this.renderApps(this.props.apps);
    }
    return (
      <div>
        <Row>
        {appsHtml}
        </Row>
      </div>
    );
  }
}

export default AppSelector;