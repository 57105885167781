import React from "react";
import { Row, Col } from "reactstrap";

import I18nBlock from './I18nBlock';

class I18nTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newData: props.newData || {}
    };
  }

  getlanguageValue(lang, key) {
    if (this.state.newData && lang in this.state.newData && key in this.state.newData[lang]) {
      return this.state.newData[lang][key];
    }
    if (this.props.data && this.props.data.i18n && lang in this.props.data.i18n && key in this.props.data.i18n[lang]) {
      return this.props.data.i18n[lang][key];
    }
  }

  getFieldValue(key) {
    //console.log('I18nTab --> getFieldValue(%s) | this.state.newData: %o | this.props.data: %o', key, this.state.newData, this.props.data);
    let value = {};
    if (this.props.data.configs && this.props.data.configs.availableLanguages) {
      this.props.data.configs.availableLanguages.forEach(element => {
        value[element] = this.getlanguageValue(element, key);
      });
    }
    return value;
  }

  handleNewData(change) {
    this.props.onNewData(change);
    this.setState({
      newData: change
    })
  }

  isVersionLowerOrEqual(element_version, app_version) {
    element_version = element_version.split('.').map(function (el) { return parseInt(el) });
    app_version = app_version.split('.').map(function (el) { return parseInt(el) });
    for (var i = 0; i < element_version.length; i++) {
      //console.log("---->>>> ---->>>> %s < %s", element_version[i], app_version[i])
      if (element_version[i] < app_version[i] && i + 1 < element_version.length) {
        return true;
      }
      if (element_version[i] <= app_version[i] && i + 1 === element_version.length) {
        return true;
      }
      if (element_version[i] > app_version[i]) {
        return false;
      }
    }
    return false;
  };

  renderBlocks() {
    if (this.props.fields) {
      return this.props.fields.map((element, index) => {
        if (this.isVersionLowerOrEqual(element.version, this.props.selectedVersion)) {
          return (
            <I18nBlock key={element.blockTitle} element={element} onNewData={this.handleNewData.bind(this)} newData={this.state.newData}
            data={this.props.data} selectedVersion={this.props.selectedVersion}></I18nBlock>
          );
        }
        return "";
      });
    }
  }


  render() {
    return (
      <div>
        <Row>
          <Col md={{ size: 12 }}>
            <br />
            {this.renderBlocks()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default I18nTab;