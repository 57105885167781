let utils = {
  config126: [
    "version",
    "debugMode",
    "testfairyLog",
    "apiUrl",
    "apiLocalhostUrl",
    "apiv2Url",
    "apiv2LocalhostUrl",
    "authUrl",
    "authLocalhostUrl",
    "portalUrl",
    "lang",
    "availableLanguages",
    "currencySymbol",
    "currencyCode",
    "registrationUser",
    "registrationPass",
    "carClubCode",
    "countryCode",
    "confirmBookingForOtherCarClub",
    "validateCarClubOnLogin",
    "defaultLocationCode",
    "validatePinOnBooking",
    "validatePinOnDamage",
    "groupsAsBonusRecomendations",
    "bluetoothComunication",
    "openDoorSmsAuthorization",
    "rateTrip",
    "registrationAvailable",
    "showLoginImage",
    "timeMinStartGap",
    "timeMaxStartGap",
    "timeMinDuration",
    "timeMaxDuration",
    "timeSelectInterval",
    "timeRoundToTheInterval",
    "hideCarDetailsSimulation",
    "hideCarListButton",
    "showExtendBtnInMap",
    "timeInUseIsTotal",
    "hideFuelInfo",
    "validateCreditCardOnBooking",
    "showTripInfoCost",
    "showTimeAndDistanceOnActiveTrip",
    "onewayBlockZoneExpire",
    "distanceToOpenDoors",
    "timeToAllowEndTrip",
    "showSettingsPage",
    "i18nForceKeysEnabled",
    "epShow",
    "epImmediateTime",
    "epImmediateKm",
    "epImmediateStandby",
    "epAdvancedKm",
    "epAdvancedStandby",
    "map",
    "customerLocationThreshold",
    "thridPartyPayment",
    "iyzipayUrl",
    "iyzipayApiKey",
    "iyzipaySecret",
    "payFortAccessToken",
    "payFortMerchantId",
    "payFortGatewayUrl",
    "payuClientId",
    "payuSecret",
    "payuApiUrl",
    "peachpayRemoteUrl",
    "spreedlyEnvironmentKey",
    "spreedlyAccessSecret",
    "spreedlyAcceptedCards",
    "availablePaymentMethods",
    "topUpMultibank",
    "topUpCreditCard",
    "topUpValues",
    "skipPaymentOptionWhenInGroup",
    "documentMaxHeight",
    "documentMaxWidth",
    "copyIdCardToTaxNumber",
    "copyDrivingNumberToIdCard",
    "showSubscriptions",
    "accountTabs",
    "regStates",
    "mandatoryRegFields",
    "showRegFields"
  ],

  getConfigStringFromFile: function (file, callback) {
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        let data = e.target.result;

        let confStr = data.substring(
          data.indexOf("{") + 1,
          data.lastIndexOf("}", data.lastIndexOf("}") - 1)
        );

        confStr = confStr
          .split(" ")
          .join("")
          .split("\n")
          .join("");

        callback(confStr);
      };
    })(file);
    reader.readAsText(file);
  },

  getFileAsJson: function (file, callback) {
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        let data = e.target.result;

        let dataStr = data
          .split(" ")
          .join("");

        try {
          let result = JSON.parse(dataStr);
          callback(result);
        } catch (exception) {
          callback(dataStr, exception);
        }
      };
    })(file);
    reader.readAsText(file);
  },


  getFileAsText: function (file, callback) {
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        let data = e.target.result;
        callback(data);
      };
    })(file);
    reader.readAsText(file);
  },

  lineCounting: function (str, endIndex) {
    if (endIndex > str.length || !endIndex || isNaN(endIndex)) {
      endIndex = str.length;
    }
    let startIndex = 0;
    let count = 0;
    do {
      count++;
      startIndex = str.indexOf('\n', startIndex + 1);
    }
    while (startIndex < endIndex && startIndex > 0);
    return count;
  },

  getConfigValue: function (configStr, key) {
    let init = configStr.indexOf(key) + key.length + 1;
    let end = init + 1;
    let initStr = configStr.substr(init, 1);
    switch (initStr) {
      case "{":
        end = configStr.indexOf("}", end);
        return this.tryParse(configStr.substring(init, end + 1));
      case "[":
        end = configStr.indexOf("]", end);
        return this.tryParse(
          configStr
            .substring(init, end + 1)
            .split("'")
            .join('"')
        );
      case '"':
        end = configStr.indexOf('"', end);
        return this.tryParse(configStr.substring(init, end + 1));
      default:
        end = configStr.indexOf(",", end);
        return this.tryParse(configStr.substring(init, end));
    }
  },

  tryParse: function (str) {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  },

  downloadFile: function (filename, fileType, body) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      fileType + "," + encodeURIComponent(body)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },

  download: function (filename, text) {
    this.downloadFile(filename, 'data:text/plain;charset=utf-8', text);
  },

  exportCsv: function (filename, rows) {
    this.downloadFile(filename, 'data:text/csv;charset=utf-8', rows.map(e => e.join(",")).join("\n"));
  }
};

export { utils };
