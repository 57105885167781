const carsReducer = (state = {
  loading: false,
  cars: null,
  brands: null,
  classes: null,
  groups: null,
  lastDataResult: null,
  error: false
}, action) => {
  switch (action.type) {
    case "MBG_CARS_GET_CARS":
      state = {
        ...state,
        loading: true,
        lastDataResult: null,
        error: false
      };
      break;
    case "MBG_CARS_GET_CARS_SUCCESS":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload,
        cars: action.payload
      };
      break;
    case "MBG_CARS_GET_CARS_FAILURE":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload.response,
        errorObject: action.payload.response,
        error: true
      };
      break;
    case "MBG_CARS_GET_BRANDS":
      state = {
        ...state,
        loading: true,
        lastDataResult: null,
        getBrandsError: null,
        brands: null,
        error: false
      };
      break;
    case "MBG_CARS_GET_BRANDS_SUCCESS":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload,
        brands: action.payload
      };
      break;
    case "MBG_CARS_GET_BRANDS_FAILURE":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload.response,
        getBrandsError: action.payload.response,
        error: true
      };
      break;
    case "MBG_CARS_GET_CLASSES":
      state = {
        ...state,
        loading: true,
        lastDataResult: null,
        getClassesError: null,
        classes: null,
        error: false
      };
      break;
    case "MBG_CARS_GET_CLASSES_SUCCESS":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload,
        classes: action.payload
      };
      break;
    case "MBG_CARS_GET_CLASSES_FAILURE":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload.response,
        getClassesError: action.payload.response,
        error: true
      };
      break;
    case "MBG_CARS_GET_GROUPS":
      state = {
        ...state,
        loading: true,
        lastDataResult: null,
        error: false
      };
      break;
    case "MBG_CARS_GET_GROUPS_SUCCESS":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload,
        groups: action.payload
      };
      break;
    case "MBG_CARS_GET_GROUPS_FAILURE":
      state = {
        ...state,
        loading: false,
        lastDataResult: action.payload.response,
        errorObject: action.payload.response,
        error: true
      };
      break;
      case "MBG_CARS_GET_CAR_VIRTUAL_KEY":
        state = {
          ...state,
          loading: true,
          lastDataResult: null,
          getCarVirtualKeyError: null,
          virtualKey: null,
          error: false
        };
        break;
      case "MBG_CARS_GET_CAR_VIRTUAL_KEY_SUCCESS":
        state = {
          ...state,
          loading: false,
          lastDataResult: action.payload,
          virtualKey: action.payload
        };
        break;
      case "MBG_CARS_GET_CAR_VIRTUAL_KEY_FAILURE":
        state = {
          ...state,
          loading: false,
          lastDataResult: action.payload.response,
          getCarVirtualKeyError: action.payload.response,
          errorObject: action.payload.response,
          error: true
        };
        break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default carsReducer;
