import mobiagApi from "../api/mobiag-api";

export function authenticate(username, password, env, dispatch) {
  dispatch({
    type: "AUTHENTICATE"
  });
  if (username === "admin" && password === "admin123") {
    localStorage.setItem('authToken', '123');
    dispatch({
      type: "AUTHENTICATE_SUCCESS",
      payload: {
        username: username,
        response: "success"
      }
    });
  }
  else {
    dispatch({
      type: "AUTHENTICATE_FAILURE",
      payload: "failed login"
    });
  }
  // mobiagApi.authenticate(username, password, env, (response, error) => {
  //   if (!error) {
  //   }
  //   else {
  //   }
  // });
}

export function isAuthenticated(dispatch) {
  let authToken = localStorage.getItem('authToken');
  if (authToken) {
    dispatch({
      type: "IS_AUTHENTICATED"
    });
    return true;
  }
  return false;
}

export function logout(dispatch) {
  mobiagApi.logout();
  dispatch({
    type: "LOGOUT"
  });
}