import React, { Component } from "react";
import { connect } from "react-redux";
import { getCars, getBrands, getClasses, getGroups, getCarVirtualKey } from "../../Actions/carsActions";

import {
  Button
} from "reactstrap";
import MethodBox from "../../Components/MethodBox";

class CarsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { optionOneOpen: this.props.open };
  }
  toggle() {
    this.setState({ optionOneOpen: !this.state.optionOneOpen });
  }
  render() {
    return <div>
      <br />
      <h5>CARS</h5>
      <hr/>
      <MethodBox title="Get Brands" result={this.props.brands} error={this.props.getBrandsError}>
        <Button type="primary" disabled={this.props.loading} onClick={() => this.props.getBrands()}>Submit</Button>
      </MethodBox>
      <MethodBox title="Get Classes" result={this.props.classes} error={(this.props.getClassesError)}>
        <Button type="primary" disabled={(this.props.loading)} onClick={() => this.props.getClasses()}>Submit</Button>
      </MethodBox>
      <MethodBox title="Get Car Virtual Key" result={this.props.virtualKey} error={(this.props.getCarVirtualKeyError)}>
        <Button type="primary" disabled={(this.props.loading)} onClick={() => this.props.getCarVirtualKey()}>Submit</Button>
      </MethodBox>

    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.carsReducer.loading,
    brands: state.carsReducer.brands,
    getBrandsError: state.carsReducer.getBrandsError,
    classes: state.carsReducer.classes,
    getClassesError: state.carsReducer.getClassesError,
    virtualKey: state.carsReducer.virtualKey,
    getCarVirtualKeyError: state.carsReducer.getCarVirtualKeyError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCars: () => {
      getCars("LSB", dispatch);
    },
    getBrands: () => {
      getBrands(3, dispatch);
    },
    getClasses: () => {
      getClasses(10, dispatch);
    },
    getGroups: () => {
      getGroups(null, dispatch);
    },
    getCarVirtualKey: () => {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      getCarVirtualKey('68671176-5942-401b-89a9-43098e1911d8', 'c9da5a26257a2b25', date, 'RMBG19011655', dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CarsContainer);
